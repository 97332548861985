/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: saveDraft
// ====================================================

export interface saveDraft_saveDraft_case_additionalCandidateAttachments_candidateAttachments {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface saveDraft_saveDraft_case_additionalCandidateAttachments {
  __typename: "AdditionalCandidateAttachments";
  note: string | null;
  whenUploaded: ScalarLong;
  candidateAttachments: saveDraft_saveDraft_case_additionalCandidateAttachments_candidateAttachments[];
}

export interface saveDraft_saveDraft_case_candidate {
  __typename: "Candidate";
  firstName: string;
  lastName: string;
  emailAddress: string;
}

export interface saveDraft_saveDraft_case_eSignature {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface saveDraft_saveDraft_case_trustIdApplication_state {
  __typename: "TrustIdApplicationState";
  status: TrustIDApplicationStatus;
}

export interface saveDraft_saveDraft_case_trustIdApplication {
  __typename: "CandidateTrustIdApplication";
  state: saveDraft_saveDraft_case_trustIdApplication_state;
}

export interface saveDraft_saveDraft_case_consentForm {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface saveDraft_saveDraft_case_originalConsentForm {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface saveDraft_saveDraft_case_client_logo {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface saveDraft_saveDraft_case_client {
  __typename: "CandidateClient";
  id: string;
  name: string;
  liveChatEnabled: boolean;
  logo: saveDraft_saveDraft_case_client_logo | null;
}

export interface saveDraft_saveDraft_case_sectionsByAffectingField {
  __typename: "SectionsByAffectingField";
  conditionalProductsAffectedSections: Section[];
  fieldId: string;
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction_values_title;
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction_values[];
}

export type saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions = saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_DateRestriction | saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_DurationRestriction | saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_GapRestriction | saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_LengthRestriction | saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_NumericRestriction | saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction;

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field_opsFields = saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field_opsFields_OpsEquifaxFieldValue | saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field_opsFields_OpsEbulkFieldValue | saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field_opsFields_OpsOcrFieldValue;

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions[];
  description: saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field_description;
  title: saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field_title;
  opsFields: saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field_opsFields[];
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_LongValue {
  __typename: "LongValue";
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_Attachments {
  __typename: "Attachments";
  attachmentsValue: saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_Attachments_attachmentsValue[];
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_Company {
  __typename: "Company";
  name: string;
  address: saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_Company_address;
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_QualificationsAndGrades_qualificationsAndGrades[];
}

export type saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value = saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_LongValue | saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_Address | saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_Attachment | saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_Attachments | saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_BooleanValue | saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_Company | saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_Country | saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_DateValue | saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_FloatValue | saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_StringValue | saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_DateRange | saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_StringValues | saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value_QualificationsAndGrades;

export interface saveDraft_saveDraft_case_sections_answers_answers_fieldValues {
  __typename: "CandidateFieldValue";
  skip: boolean;
  field: saveDraft_saveDraft_case_sections_answers_answers_fieldValues_field;
  value: saveDraft_saveDraft_case_sections_answers_answers_fieldValues_value | null;
}

export interface saveDraft_saveDraft_case_sections_answers_answers {
  __typename: "CandidateAnswerDetails";
  isCandidateAnswer: boolean;
  id: string;
  fieldValues: saveDraft_saveDraft_case_sections_answers_answers_fieldValues[];
}

export interface saveDraft_saveDraft_case_sections_answers_question_fields_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface saveDraft_saveDraft_case_sections_answers_question_fields_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface saveDraft_saveDraft_case_sections_answers_question_fields_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface saveDraft_saveDraft_case_sections_answers_question_fields_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface saveDraft_saveDraft_case_sections_answers_question_fields_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface saveDraft_saveDraft_case_sections_answers_question_fields_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveDraft_saveDraft_case_sections_answers_question_fields_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: saveDraft_saveDraft_case_sections_answers_question_fields_restrictions_SelectRestriction_values_title;
}

export interface saveDraft_saveDraft_case_sections_answers_question_fields_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: saveDraft_saveDraft_case_sections_answers_question_fields_restrictions_SelectRestriction_values[];
}

export type saveDraft_saveDraft_case_sections_answers_question_fields_restrictions = saveDraft_saveDraft_case_sections_answers_question_fields_restrictions_DateRestriction | saveDraft_saveDraft_case_sections_answers_question_fields_restrictions_DurationRestriction | saveDraft_saveDraft_case_sections_answers_question_fields_restrictions_GapRestriction | saveDraft_saveDraft_case_sections_answers_question_fields_restrictions_LengthRestriction | saveDraft_saveDraft_case_sections_answers_question_fields_restrictions_NumericRestriction | saveDraft_saveDraft_case_sections_answers_question_fields_restrictions_SelectRestriction;

export interface saveDraft_saveDraft_case_sections_answers_question_fields_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveDraft_saveDraft_case_sections_answers_question_fields_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveDraft_saveDraft_case_sections_answers_question_fields_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface saveDraft_saveDraft_case_sections_answers_question_fields_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface saveDraft_saveDraft_case_sections_answers_question_fields_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type saveDraft_saveDraft_case_sections_answers_question_fields_opsFields = saveDraft_saveDraft_case_sections_answers_question_fields_opsFields_OpsEquifaxFieldValue | saveDraft_saveDraft_case_sections_answers_question_fields_opsFields_OpsEbulkFieldValue | saveDraft_saveDraft_case_sections_answers_question_fields_opsFields_OpsOcrFieldValue;

export interface saveDraft_saveDraft_case_sections_answers_question_fields {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: saveDraft_saveDraft_case_sections_answers_question_fields_restrictions[];
  description: saveDraft_saveDraft_case_sections_answers_question_fields_description;
  title: saveDraft_saveDraft_case_sections_answers_question_fields_title;
  opsFields: saveDraft_saveDraft_case_sections_answers_question_fields_opsFields[];
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction_values_title;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction_values[];
}

export type saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions = saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_DateRestriction | saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_DurationRestriction | saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_GapRestriction | saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_LengthRestriction | saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_NumericRestriction | saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction;

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields_opsFields = saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields_opsFields_OpsEquifaxFieldValue | saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields_opsFields_OpsEbulkFieldValue | saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields_opsFields_OpsOcrFieldValue;

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions[];
  description: saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields_description;
  title: saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields_title;
  opsFields: saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields_opsFields[];
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values_title;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values[];
}

export type saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions = saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_DateRestriction | saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_DurationRestriction | saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_GapRestriction | saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_LengthRestriction | saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_NumericRestriction | saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction;

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields = saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue | saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue | saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsOcrFieldValue;

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions[];
  description: saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_description;
  title: saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_title;
  opsFields: saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields[];
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_LongValue {
  __typename: "LongValue";
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachments {
  __typename: "Attachments";
  attachmentsValue: saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachments_attachmentsValue[];
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company {
  __typename: "Company";
  name: string;
  address: saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company_address;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades[];
}

export type saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value = saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_LongValue | saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Address | saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachment | saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachments | saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_BooleanValue | saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company | saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Country | saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_DateValue | saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_FloatValue | saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_StringValue | saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_DateRange | saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_StringValues | saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades;

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue {
  __typename: "CandidateFieldValue";
  field: saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field;
  value: saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value | null;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions {
  __typename: "CandidateFieldCondition";
  id: string | null;
  fieldValue: saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue;
}

export interface saveDraft_saveDraft_case_sections_answers_question_conditionalElements {
  __typename: "CandidateQuestionConditionalFields";
  id: string | null;
  /**
   * Fields to be enabled when condition is true.
   */
  fields: saveDraft_saveDraft_case_sections_answers_question_conditionalElements_fields[];
  conditions: saveDraft_saveDraft_case_sections_answers_question_conditionalElements_conditions[];
}

export interface saveDraft_saveDraft_case_sections_answers_question {
  __typename: "CandidateQuestion";
  id: string;
  /**
   *  TODO: check if sectionOrder is needed here
   * sectionOrder: CandidateSectionOrder!
   */
  conditional: boolean;
  /**
   * Whether the user can answer the question multiple times, like previous job
   */
  multipleAnswers: boolean;
  /**
   * Identity proof needs special wording/etc at the candidate portal.
   */
  questionType: QuestionType;
  title: string;
  fields: saveDraft_saveDraft_case_sections_answers_question_fields[];
  conditionalElements: saveDraft_saveDraft_case_sections_answers_question_conditionalElements[];
}

export interface saveDraft_saveDraft_case_sections_answers {
  __typename: "CandidateAnswer";
  conditionFulfilled: boolean;
  answers: saveDraft_saveDraft_case_sections_answers_answers[];
  question: saveDraft_saveDraft_case_sections_answers_question;
}

export interface saveDraft_saveDraft_case_sections {
  __typename: "CandidateDataSection";
  /**
   * Whether all questions in this section has been answered.
   */
  completed: boolean;
  section: Section;
  /**
   * Provides information for both questions and optional answers.
   * If some question allows multiple answers then several Answer structures come with the same questionId in this array.
   */
  answers: saveDraft_saveDraft_case_sections_answers[];
}

export interface saveDraft_saveDraft_case_contract_support {
  __typename: "CandidateContractSupport";
  supportEmailAddress: string;
  supportPhoneNumber: string;
}

export interface saveDraft_saveDraft_case_contract {
  __typename: "CandidateContract";
  id: string;
  support: saveDraft_saveDraft_case_contract_support;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction_values_title;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction_values[];
}

export type saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions = saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_DateRestriction | saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_DurationRestriction | saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_GapRestriction | saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_LengthRestriction | saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_NumericRestriction | saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction;

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields = saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue | saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue | saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsOcrFieldValue;

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions[];
  description: saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_description;
  title: saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_title;
  opsFields: saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields[];
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_LongValue {
  __typename: "LongValue";
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Attachments {
  __typename: "Attachments";
  attachmentsValue: saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Attachments_attachmentsValue[];
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Company {
  __typename: "Company";
  name: string;
  address: saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Company_address;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades[];
}

export type saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value = saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_LongValue | saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Address | saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Attachment | saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Attachments | saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_BooleanValue | saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Company | saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Country | saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_DateValue | saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_FloatValue | saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_StringValue | saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_DateRange | saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_StringValues | saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades;

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue {
  __typename: "CandidateFieldValue";
  field: saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field;
  value: saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value | null;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions {
  __typename: "CandidateFieldCondition";
  id: string | null;
  fieldValue: saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction_values_title;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction_values[];
}

export type saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions = saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_DateRestriction | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_DurationRestriction | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_GapRestriction | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_LengthRestriction | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_NumericRestriction | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction;

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields_opsFields = saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields_opsFields_OpsEquifaxFieldValue | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields_opsFields_OpsEbulkFieldValue | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields_opsFields_OpsOcrFieldValue;

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions[];
  description: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields_description;
  title: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields_title;
  opsFields: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields_opsFields[];
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction_values_title;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction_values[];
}

export type saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions = saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_DateRestriction | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_DurationRestriction | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_GapRestriction | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_LengthRestriction | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_NumericRestriction | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction;

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields = saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsEquifaxFieldValue | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsEbulkFieldValue | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsOcrFieldValue;

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions[];
  description: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_description;
  title: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_title;
  opsFields: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields[];
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values_title;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values[];
}

export type saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions = saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_DateRestriction | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_DurationRestriction | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_GapRestriction | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_LengthRestriction | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_NumericRestriction | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction;

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields = saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsOcrFieldValue;

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions[];
  description: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_description;
  title: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_title;
  opsFields: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields[];
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_LongValue {
  __typename: "LongValue";
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachments {
  __typename: "Attachments";
  attachmentsValue: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachments_attachmentsValue[];
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company {
  __typename: "Company";
  name: string;
  address: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company_address;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades[];
}

export type saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value = saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_LongValue | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Address | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachment | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachments | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_BooleanValue | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Country | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_DateValue | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_FloatValue | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_StringValue | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_DateRange | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_StringValues | saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades;

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue {
  __typename: "CandidateFieldValue";
  field: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field;
  value: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value | null;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions {
  __typename: "CandidateFieldCondition";
  id: string | null;
  fieldValue: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue;
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements {
  __typename: "CandidateQuestionConditionalFields";
  id: string | null;
  /**
   * Fields to be enabled when condition is true.
   */
  fields: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields[];
  conditions: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions[];
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions_question {
  __typename: "CandidateQuestion";
  id: string;
  /**
   *  TODO: check if sectionOrder is needed here
   * sectionOrder: CandidateSectionOrder!
   */
  conditional: boolean;
  /**
   * Whether the user can answer the question multiple times, like previous job
   */
  multipleAnswers: boolean;
  /**
   * Identity proof needs special wording/etc at the candidate portal.
   */
  questionType: QuestionType;
  title: string;
  fields: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_fields[];
  conditionalElements: saveDraft_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements[];
}

export interface saveDraft_saveDraft_case_products_product_conditionalQuestions {
  __typename: "CandidateProductConditionalQuestion";
  id: string | null;
  conditions: saveDraft_saveDraft_case_products_product_conditionalQuestions_conditions[];
  /**
   * Questions to be enabled when condition is true.
   */
  question: saveDraft_saveDraft_case_products_product_conditionalQuestions_question;
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_answersCondition {
  __typename: "CandidateAnswersCondition";
  maxNumberOfAnswers: number | null;
  minNumberOfAnswers: number | null;
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction_values_title;
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction_values[];
}

export type saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions = saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_DateRestriction | saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_DurationRestriction | saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_GapRestriction | saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_LengthRestriction | saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_NumericRestriction | saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction;

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields = saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue | saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue | saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsOcrFieldValue;

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions[];
  description: saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_description;
  title: saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_title;
  opsFields: saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields[];
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_LongValue {
  __typename: "LongValue";
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Attachments {
  __typename: "Attachments";
  attachmentsValue: saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Attachments_attachmentsValue[];
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Company {
  __typename: "Company";
  name: string;
  address: saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Company_address;
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades[];
}

export type saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value = saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_LongValue | saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Address | saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Attachment | saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Attachments | saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_BooleanValue | saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Company | saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Country | saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_DateValue | saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_FloatValue | saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_StringValue | saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_DateRange | saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_StringValues | saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades;

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue {
  __typename: "CandidateFieldValue";
  field: saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field;
  value: saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value | null;
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations_conditions {
  __typename: "CandidateFieldCondition";
  id: string | null;
  fieldValue: saveDraft_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue;
}

export interface saveDraft_saveDraft_case_products_product_conditionalValidations {
  __typename: "CandidateProductConditionalValidation";
  id: string | null;
  validationMessage: string;
  answersCondition: saveDraft_saveDraft_case_products_product_conditionalValidations_answersCondition | null;
  conditions: saveDraft_saveDraft_case_products_product_conditionalValidations_conditions[];
}

export interface saveDraft_saveDraft_case_products_product {
  __typename: "CandidateProduct";
  id: string;
  conditionalQuestions: saveDraft_saveDraft_case_products_product_conditionalQuestions[];
  conditionalValidations: saveDraft_saveDraft_case_products_product_conditionalValidations[];
}

export interface saveDraft_saveDraft_case_products {
  __typename: "CandidateCaseProduct";
  id: string;
  status: CandidateCaseProductStatus;
  name: string;
  product: saveDraft_saveDraft_case_products_product;
}

export interface saveDraft_saveDraft_case {
  __typename: "CandidateCase";
  id: string;
  code: string;
  status: CandidateCaseStatus;
  /**
   * Assumption: deadline is hard-coded number of days for now
   */
  deadline: ScalarLong;
  ocrTriggered: boolean;
  additionalCandidateAttachments: saveDraft_saveDraft_case_additionalCandidateAttachments[];
  candidate: saveDraft_saveDraft_case_candidate;
  eSignature: saveDraft_saveDraft_case_eSignature | null;
  consentType: ConsentType;
  consentText: string | null;
  troubleshotByScreener: boolean;
  consentGiven: boolean;
  whenCreated: ScalarLong;
  whenCompleted: ScalarLong | null;
  trustIdApplication: saveDraft_saveDraft_case_trustIdApplication | null;
  draft: string | null;
  hasEnhancedCriminalityCheck: boolean;
  consentForm: saveDraft_saveDraft_case_consentForm | null;
  originalConsentForm: saveDraft_saveDraft_case_originalConsentForm | null;
  client: saveDraft_saveDraft_case_client;
  sectionsByAffectingField: saveDraft_saveDraft_case_sectionsByAffectingField[];
  sections: saveDraft_saveDraft_case_sections[];
  contract: saveDraft_saveDraft_case_contract;
  products: saveDraft_saveDraft_case_products[];
}

export interface saveDraft_saveDraft {
  __typename: "CandidateCaseSaveDraftPayload";
  case: saveDraft_saveDraft_case;
}

export interface saveDraft {
  saveDraft: saveDraft_saveDraft;
}

export interface saveDraftVariables {
  input: CandidateCaseSaveDraftInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: saveUploadHistory
// ====================================================

export interface saveUploadHistory_saveDraft_case_additionalCandidateAttachments_candidateAttachments {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface saveUploadHistory_saveDraft_case_additionalCandidateAttachments {
  __typename: "AdditionalCandidateAttachments";
  note: string | null;
  whenUploaded: ScalarLong;
  candidateAttachments: saveUploadHistory_saveDraft_case_additionalCandidateAttachments_candidateAttachments[];
}

export interface saveUploadHistory_saveDraft_case_candidate {
  __typename: "Candidate";
  firstName: string;
  lastName: string;
  emailAddress: string;
}

export interface saveUploadHistory_saveDraft_case_eSignature {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface saveUploadHistory_saveDraft_case_trustIdApplication_state {
  __typename: "TrustIdApplicationState";
  status: TrustIDApplicationStatus;
}

export interface saveUploadHistory_saveDraft_case_trustIdApplication {
  __typename: "CandidateTrustIdApplication";
  state: saveUploadHistory_saveDraft_case_trustIdApplication_state;
}

export interface saveUploadHistory_saveDraft_case_consentForm {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface saveUploadHistory_saveDraft_case_originalConsentForm {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface saveUploadHistory_saveDraft_case_client_logo {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface saveUploadHistory_saveDraft_case_client {
  __typename: "CandidateClient";
  id: string;
  name: string;
  liveChatEnabled: boolean;
  logo: saveUploadHistory_saveDraft_case_client_logo | null;
}

export interface saveUploadHistory_saveDraft_case_sectionsByAffectingField {
  __typename: "SectionsByAffectingField";
  conditionalProductsAffectedSections: Section[];
  fieldId: string;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction_values_title;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction_values[];
}

export type saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions = saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_DateRestriction | saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_DurationRestriction | saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_GapRestriction | saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_LengthRestriction | saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_NumericRestriction | saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction;

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field_opsFields = saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field_opsFields_OpsEquifaxFieldValue | saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field_opsFields_OpsEbulkFieldValue | saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field_opsFields_OpsOcrFieldValue;

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field_restrictions[];
  description: saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field_description;
  title: saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field_title;
  opsFields: saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field_opsFields[];
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_LongValue {
  __typename: "LongValue";
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_Attachments {
  __typename: "Attachments";
  attachmentsValue: saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_Attachments_attachmentsValue[];
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_Company {
  __typename: "Company";
  name: string;
  address: saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_Company_address;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_QualificationsAndGrades_qualificationsAndGrades[];
}

export type saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value = saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_LongValue | saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_Address | saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_Attachment | saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_Attachments | saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_BooleanValue | saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_Company | saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_Country | saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_DateValue | saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_FloatValue | saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_StringValue | saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_DateRange | saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_StringValues | saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value_QualificationsAndGrades;

export interface saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues {
  __typename: "CandidateFieldValue";
  skip: boolean;
  field: saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_field;
  value: saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues_value | null;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_answers {
  __typename: "CandidateAnswerDetails";
  isCandidateAnswer: boolean;
  id: string;
  fieldValues: saveUploadHistory_saveDraft_case_sections_answers_answers_fieldValues[];
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_fields_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_fields_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_fields_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_fields_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_fields_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_fields_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_fields_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: saveUploadHistory_saveDraft_case_sections_answers_question_fields_restrictions_SelectRestriction_values_title;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_fields_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: saveUploadHistory_saveDraft_case_sections_answers_question_fields_restrictions_SelectRestriction_values[];
}

export type saveUploadHistory_saveDraft_case_sections_answers_question_fields_restrictions = saveUploadHistory_saveDraft_case_sections_answers_question_fields_restrictions_DateRestriction | saveUploadHistory_saveDraft_case_sections_answers_question_fields_restrictions_DurationRestriction | saveUploadHistory_saveDraft_case_sections_answers_question_fields_restrictions_GapRestriction | saveUploadHistory_saveDraft_case_sections_answers_question_fields_restrictions_LengthRestriction | saveUploadHistory_saveDraft_case_sections_answers_question_fields_restrictions_NumericRestriction | saveUploadHistory_saveDraft_case_sections_answers_question_fields_restrictions_SelectRestriction;

export interface saveUploadHistory_saveDraft_case_sections_answers_question_fields_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_fields_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_fields_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_fields_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_fields_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type saveUploadHistory_saveDraft_case_sections_answers_question_fields_opsFields = saveUploadHistory_saveDraft_case_sections_answers_question_fields_opsFields_OpsEquifaxFieldValue | saveUploadHistory_saveDraft_case_sections_answers_question_fields_opsFields_OpsEbulkFieldValue | saveUploadHistory_saveDraft_case_sections_answers_question_fields_opsFields_OpsOcrFieldValue;

export interface saveUploadHistory_saveDraft_case_sections_answers_question_fields {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: saveUploadHistory_saveDraft_case_sections_answers_question_fields_restrictions[];
  description: saveUploadHistory_saveDraft_case_sections_answers_question_fields_description;
  title: saveUploadHistory_saveDraft_case_sections_answers_question_fields_title;
  opsFields: saveUploadHistory_saveDraft_case_sections_answers_question_fields_opsFields[];
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction_values_title;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction_values[];
}

export type saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions = saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_DateRestriction | saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_DurationRestriction | saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_GapRestriction | saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_LengthRestriction | saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_NumericRestriction | saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction;

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields_opsFields = saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields_opsFields_OpsEquifaxFieldValue | saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields_opsFields_OpsEbulkFieldValue | saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields_opsFields_OpsOcrFieldValue;

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields_restrictions[];
  description: saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields_description;
  title: saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields_title;
  opsFields: saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields_opsFields[];
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values_title;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values[];
}

export type saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions = saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_DateRestriction | saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_DurationRestriction | saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_GapRestriction | saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_LengthRestriction | saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_NumericRestriction | saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction;

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields = saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue | saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue | saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsOcrFieldValue;

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions[];
  description: saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_description;
  title: saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_title;
  opsFields: saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields[];
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_LongValue {
  __typename: "LongValue";
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachments {
  __typename: "Attachments";
  attachmentsValue: saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachments_attachmentsValue[];
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company {
  __typename: "Company";
  name: string;
  address: saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company_address;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades[];
}

export type saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value = saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_LongValue | saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Address | saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachment | saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachments | saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_BooleanValue | saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company | saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Country | saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_DateValue | saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_FloatValue | saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_StringValue | saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_DateRange | saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_StringValues | saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades;

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue {
  __typename: "CandidateFieldValue";
  field: saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_field;
  value: saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue_value | null;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions {
  __typename: "CandidateFieldCondition";
  id: string | null;
  fieldValue: saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions_fieldValue;
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements {
  __typename: "CandidateQuestionConditionalFields";
  id: string | null;
  /**
   * Fields to be enabled when condition is true.
   */
  fields: saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_fields[];
  conditions: saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements_conditions[];
}

export interface saveUploadHistory_saveDraft_case_sections_answers_question {
  __typename: "CandidateQuestion";
  id: string;
  /**
   *  TODO: check if sectionOrder is needed here
   * sectionOrder: CandidateSectionOrder!
   */
  conditional: boolean;
  /**
   * Whether the user can answer the question multiple times, like previous job
   */
  multipleAnswers: boolean;
  /**
   * Identity proof needs special wording/etc at the candidate portal.
   */
  questionType: QuestionType;
  title: string;
  fields: saveUploadHistory_saveDraft_case_sections_answers_question_fields[];
  conditionalElements: saveUploadHistory_saveDraft_case_sections_answers_question_conditionalElements[];
}

export interface saveUploadHistory_saveDraft_case_sections_answers {
  __typename: "CandidateAnswer";
  conditionFulfilled: boolean;
  answers: saveUploadHistory_saveDraft_case_sections_answers_answers[];
  question: saveUploadHistory_saveDraft_case_sections_answers_question;
}

export interface saveUploadHistory_saveDraft_case_sections {
  __typename: "CandidateDataSection";
  /**
   * Whether all questions in this section has been answered.
   */
  completed: boolean;
  section: Section;
  /**
   * Provides information for both questions and optional answers.
   * If some question allows multiple answers then several Answer structures come with the same questionId in this array.
   */
  answers: saveUploadHistory_saveDraft_case_sections_answers[];
}

export interface saveUploadHistory_saveDraft_case_contract_support {
  __typename: "CandidateContractSupport";
  supportEmailAddress: string;
  supportPhoneNumber: string;
}

export interface saveUploadHistory_saveDraft_case_contract {
  __typename: "CandidateContract";
  id: string;
  support: saveUploadHistory_saveDraft_case_contract_support;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction_values_title;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction_values[];
}

export type saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions = saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_DateRestriction | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_DurationRestriction | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_GapRestriction | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_LengthRestriction | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_NumericRestriction | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction;

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields = saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsOcrFieldValue;

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions[];
  description: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_description;
  title: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_title;
  opsFields: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields[];
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_LongValue {
  __typename: "LongValue";
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Attachments {
  __typename: "Attachments";
  attachmentsValue: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Attachments_attachmentsValue[];
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Company {
  __typename: "Company";
  name: string;
  address: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Company_address;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades[];
}

export type saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value = saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_LongValue | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Address | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Attachment | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Attachments | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_BooleanValue | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Company | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_Country | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_DateValue | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_FloatValue | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_StringValue | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_DateRange | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_StringValues | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades;

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue {
  __typename: "CandidateFieldValue";
  field: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_field;
  value: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue_value | null;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions {
  __typename: "CandidateFieldCondition";
  id: string | null;
  fieldValue: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions_fieldValue;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction_values_title;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction_values[];
}

export type saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions = saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_DateRestriction | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_DurationRestriction | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_GapRestriction | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_LengthRestriction | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_NumericRestriction | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction;

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields_opsFields = saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields_opsFields_OpsEquifaxFieldValue | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields_opsFields_OpsEbulkFieldValue | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields_opsFields_OpsOcrFieldValue;

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields_restrictions[];
  description: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields_description;
  title: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields_title;
  opsFields: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields_opsFields[];
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction_values_title;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction_values[];
}

export type saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions = saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_DateRestriction | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_DurationRestriction | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_GapRestriction | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_LengthRestriction | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_NumericRestriction | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction;

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields = saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsEquifaxFieldValue | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsEbulkFieldValue | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsOcrFieldValue;

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions[];
  description: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_description;
  title: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_title;
  opsFields: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields[];
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values_title;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values[];
}

export type saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions = saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_DateRestriction | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_DurationRestriction | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_GapRestriction | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_LengthRestriction | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_NumericRestriction | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction;

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields = saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsOcrFieldValue;

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions[];
  description: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_description;
  title: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_title;
  opsFields: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields[];
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_LongValue {
  __typename: "LongValue";
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachments {
  __typename: "Attachments";
  attachmentsValue: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachments_attachmentsValue[];
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company {
  __typename: "Company";
  name: string;
  address: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company_address;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades[];
}

export type saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value = saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_LongValue | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Address | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachment | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachments | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_BooleanValue | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Country | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_DateValue | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_FloatValue | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_StringValue | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_DateRange | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_StringValues | saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades;

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue {
  __typename: "CandidateFieldValue";
  field: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field;
  value: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value | null;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions {
  __typename: "CandidateFieldCondition";
  id: string | null;
  fieldValue: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements {
  __typename: "CandidateQuestionConditionalFields";
  id: string | null;
  /**
   * Fields to be enabled when condition is true.
   */
  fields: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_fields[];
  conditions: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements_conditions[];
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question {
  __typename: "CandidateQuestion";
  id: string;
  /**
   *  TODO: check if sectionOrder is needed here
   * sectionOrder: CandidateSectionOrder!
   */
  conditional: boolean;
  /**
   * Whether the user can answer the question multiple times, like previous job
   */
  multipleAnswers: boolean;
  /**
   * Identity proof needs special wording/etc at the candidate portal.
   */
  questionType: QuestionType;
  title: string;
  fields: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_fields[];
  conditionalElements: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question_conditionalElements[];
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalQuestions {
  __typename: "CandidateProductConditionalQuestion";
  id: string | null;
  conditions: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_conditions[];
  /**
   * Questions to be enabled when condition is true.
   */
  question: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions_question;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_answersCondition {
  __typename: "CandidateAnswersCondition";
  maxNumberOfAnswers: number | null;
  minNumberOfAnswers: number | null;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction_values_title;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction_values[];
}

export type saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions = saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_DateRestriction | saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_DurationRestriction | saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_GapRestriction | saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_LengthRestriction | saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_NumericRestriction | saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction;

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields = saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue | saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue | saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsOcrFieldValue;

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions[];
  description: saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_description;
  title: saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_title;
  opsFields: saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields[];
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_LongValue {
  __typename: "LongValue";
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Attachments {
  __typename: "Attachments";
  attachmentsValue: saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Attachments_attachmentsValue[];
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Company {
  __typename: "Company";
  name: string;
  address: saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Company_address;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades[];
}

export type saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value = saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_LongValue | saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Address | saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Attachment | saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Attachments | saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_BooleanValue | saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Company | saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_Country | saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_DateValue | saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_FloatValue | saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_StringValue | saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_DateRange | saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_StringValues | saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades;

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue {
  __typename: "CandidateFieldValue";
  field: saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_field;
  value: saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue_value | null;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions {
  __typename: "CandidateFieldCondition";
  id: string | null;
  fieldValue: saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions_fieldValue;
}

export interface saveUploadHistory_saveDraft_case_products_product_conditionalValidations {
  __typename: "CandidateProductConditionalValidation";
  id: string | null;
  validationMessage: string;
  answersCondition: saveUploadHistory_saveDraft_case_products_product_conditionalValidations_answersCondition | null;
  conditions: saveUploadHistory_saveDraft_case_products_product_conditionalValidations_conditions[];
}

export interface saveUploadHistory_saveDraft_case_products_product {
  __typename: "CandidateProduct";
  id: string;
  conditionalQuestions: saveUploadHistory_saveDraft_case_products_product_conditionalQuestions[];
  conditionalValidations: saveUploadHistory_saveDraft_case_products_product_conditionalValidations[];
}

export interface saveUploadHistory_saveDraft_case_products {
  __typename: "CandidateCaseProduct";
  id: string;
  status: CandidateCaseProductStatus;
  name: string;
  product: saveUploadHistory_saveDraft_case_products_product;
}

export interface saveUploadHistory_saveDraft_case {
  __typename: "CandidateCase";
  id: string;
  code: string;
  status: CandidateCaseStatus;
  /**
   * Assumption: deadline is hard-coded number of days for now
   */
  deadline: ScalarLong;
  ocrTriggered: boolean;
  additionalCandidateAttachments: saveUploadHistory_saveDraft_case_additionalCandidateAttachments[];
  candidate: saveUploadHistory_saveDraft_case_candidate;
  eSignature: saveUploadHistory_saveDraft_case_eSignature | null;
  consentType: ConsentType;
  consentText: string | null;
  troubleshotByScreener: boolean;
  consentGiven: boolean;
  whenCreated: ScalarLong;
  whenCompleted: ScalarLong | null;
  trustIdApplication: saveUploadHistory_saveDraft_case_trustIdApplication | null;
  draft: string | null;
  hasEnhancedCriminalityCheck: boolean;
  consentForm: saveUploadHistory_saveDraft_case_consentForm | null;
  originalConsentForm: saveUploadHistory_saveDraft_case_originalConsentForm | null;
  client: saveUploadHistory_saveDraft_case_client;
  sectionsByAffectingField: saveUploadHistory_saveDraft_case_sectionsByAffectingField[];
  sections: saveUploadHistory_saveDraft_case_sections[];
  contract: saveUploadHistory_saveDraft_case_contract;
  products: saveUploadHistory_saveDraft_case_products[];
}

export interface saveUploadHistory_saveDraft {
  __typename: "CandidateCaseSaveDraftPayload";
  case: saveUploadHistory_saveDraft_case;
}

export interface saveUploadHistory {
  saveDraft: saveUploadHistory_saveDraft;
}

export interface saveUploadHistoryVariables {
  input: CandidateCaseSaveDraftInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: caseSubmit
// ====================================================

export interface caseSubmit_caseSubmit_case_additionalCandidateAttachments_candidateAttachments {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface caseSubmit_caseSubmit_case_additionalCandidateAttachments {
  __typename: "AdditionalCandidateAttachments";
  note: string | null;
  whenUploaded: ScalarLong;
  candidateAttachments: caseSubmit_caseSubmit_case_additionalCandidateAttachments_candidateAttachments[];
}

export interface caseSubmit_caseSubmit_case_candidate {
  __typename: "Candidate";
  firstName: string;
  lastName: string;
  emailAddress: string;
}

export interface caseSubmit_caseSubmit_case_eSignature {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface caseSubmit_caseSubmit_case_trustIdApplication_state {
  __typename: "TrustIdApplicationState";
  status: TrustIDApplicationStatus;
}

export interface caseSubmit_caseSubmit_case_trustIdApplication {
  __typename: "CandidateTrustIdApplication";
  state: caseSubmit_caseSubmit_case_trustIdApplication_state;
}

export interface caseSubmit_caseSubmit_case_consentForm {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface caseSubmit_caseSubmit_case_originalConsentForm {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface caseSubmit_caseSubmit_case_client_logo {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface caseSubmit_caseSubmit_case_client {
  __typename: "CandidateClient";
  id: string;
  name: string;
  liveChatEnabled: boolean;
  logo: caseSubmit_caseSubmit_case_client_logo | null;
}

export interface caseSubmit_caseSubmit_case_sectionsByAffectingField {
  __typename: "SectionsByAffectingField";
  conditionalProductsAffectedSections: Section[];
  fieldId: string;
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction_values_title;
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction_values[];
}

export type caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field_restrictions = caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field_restrictions_DateRestriction | caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field_restrictions_DurationRestriction | caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field_restrictions_GapRestriction | caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field_restrictions_LengthRestriction | caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field_restrictions_NumericRestriction | caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction;

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field_opsFields = caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field_opsFields_OpsEquifaxFieldValue | caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field_opsFields_OpsEbulkFieldValue | caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field_opsFields_OpsOcrFieldValue;

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field_restrictions[];
  description: caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field_description;
  title: caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field_title;
  opsFields: caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field_opsFields[];
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_LongValue {
  __typename: "LongValue";
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_Attachments {
  __typename: "Attachments";
  attachmentsValue: caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_Attachments_attachmentsValue[];
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_Company {
  __typename: "Company";
  name: string;
  address: caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_Company_address;
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_QualificationsAndGrades_qualificationsAndGrades[];
}

export type caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value = caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_LongValue | caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_Address | caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_Attachment | caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_Attachments | caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_BooleanValue | caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_Company | caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_Country | caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_DateValue | caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_FloatValue | caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_StringValue | caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_DateRange | caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_StringValues | caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value_QualificationsAndGrades;

export interface caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues {
  __typename: "CandidateFieldValue";
  skip: boolean;
  field: caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_field;
  value: caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues_value | null;
}

export interface caseSubmit_caseSubmit_case_sections_answers_answers {
  __typename: "CandidateAnswerDetails";
  isCandidateAnswer: boolean;
  id: string;
  fieldValues: caseSubmit_caseSubmit_case_sections_answers_answers_fieldValues[];
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_fields_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_fields_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_fields_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_fields_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_fields_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_fields_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_fields_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: caseSubmit_caseSubmit_case_sections_answers_question_fields_restrictions_SelectRestriction_values_title;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_fields_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: caseSubmit_caseSubmit_case_sections_answers_question_fields_restrictions_SelectRestriction_values[];
}

export type caseSubmit_caseSubmit_case_sections_answers_question_fields_restrictions = caseSubmit_caseSubmit_case_sections_answers_question_fields_restrictions_DateRestriction | caseSubmit_caseSubmit_case_sections_answers_question_fields_restrictions_DurationRestriction | caseSubmit_caseSubmit_case_sections_answers_question_fields_restrictions_GapRestriction | caseSubmit_caseSubmit_case_sections_answers_question_fields_restrictions_LengthRestriction | caseSubmit_caseSubmit_case_sections_answers_question_fields_restrictions_NumericRestriction | caseSubmit_caseSubmit_case_sections_answers_question_fields_restrictions_SelectRestriction;

export interface caseSubmit_caseSubmit_case_sections_answers_question_fields_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_fields_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_fields_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_fields_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_fields_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type caseSubmit_caseSubmit_case_sections_answers_question_fields_opsFields = caseSubmit_caseSubmit_case_sections_answers_question_fields_opsFields_OpsEquifaxFieldValue | caseSubmit_caseSubmit_case_sections_answers_question_fields_opsFields_OpsEbulkFieldValue | caseSubmit_caseSubmit_case_sections_answers_question_fields_opsFields_OpsOcrFieldValue;

export interface caseSubmit_caseSubmit_case_sections_answers_question_fields {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: caseSubmit_caseSubmit_case_sections_answers_question_fields_restrictions[];
  description: caseSubmit_caseSubmit_case_sections_answers_question_fields_description;
  title: caseSubmit_caseSubmit_case_sections_answers_question_fields_title;
  opsFields: caseSubmit_caseSubmit_case_sections_answers_question_fields_opsFields[];
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction_values_title;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction_values[];
}

export type caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields_restrictions = caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields_restrictions_DateRestriction | caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields_restrictions_DurationRestriction | caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields_restrictions_GapRestriction | caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields_restrictions_LengthRestriction | caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields_restrictions_NumericRestriction | caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction;

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields_opsFields = caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields_opsFields_OpsEquifaxFieldValue | caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields_opsFields_OpsEbulkFieldValue | caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields_opsFields_OpsOcrFieldValue;

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields_restrictions[];
  description: caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields_description;
  title: caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields_title;
  opsFields: caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields_opsFields[];
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values_title;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values[];
}

export type caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions = caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_DateRestriction | caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_DurationRestriction | caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_GapRestriction | caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_LengthRestriction | caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_NumericRestriction | caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction;

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields = caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue | caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue | caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsOcrFieldValue;

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions[];
  description: caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_description;
  title: caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_title;
  opsFields: caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields[];
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_LongValue {
  __typename: "LongValue";
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachments {
  __typename: "Attachments";
  attachmentsValue: caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachments_attachmentsValue[];
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company {
  __typename: "Company";
  name: string;
  address: caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company_address;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades[];
}

export type caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value = caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_LongValue | caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Address | caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachment | caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachments | caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_BooleanValue | caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company | caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Country | caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_DateValue | caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_FloatValue | caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_StringValue | caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_DateRange | caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_StringValues | caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades;

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue {
  __typename: "CandidateFieldValue";
  field: caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_field;
  value: caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue_value | null;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions {
  __typename: "CandidateFieldCondition";
  id: string | null;
  fieldValue: caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions_fieldValue;
}

export interface caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements {
  __typename: "CandidateQuestionConditionalFields";
  id: string | null;
  /**
   * Fields to be enabled when condition is true.
   */
  fields: caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_fields[];
  conditions: caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements_conditions[];
}

export interface caseSubmit_caseSubmit_case_sections_answers_question {
  __typename: "CandidateQuestion";
  id: string;
  /**
   *  TODO: check if sectionOrder is needed here
   * sectionOrder: CandidateSectionOrder!
   */
  conditional: boolean;
  /**
   * Whether the user can answer the question multiple times, like previous job
   */
  multipleAnswers: boolean;
  /**
   * Identity proof needs special wording/etc at the candidate portal.
   */
  questionType: QuestionType;
  title: string;
  fields: caseSubmit_caseSubmit_case_sections_answers_question_fields[];
  conditionalElements: caseSubmit_caseSubmit_case_sections_answers_question_conditionalElements[];
}

export interface caseSubmit_caseSubmit_case_sections_answers {
  __typename: "CandidateAnswer";
  conditionFulfilled: boolean;
  answers: caseSubmit_caseSubmit_case_sections_answers_answers[];
  question: caseSubmit_caseSubmit_case_sections_answers_question;
}

export interface caseSubmit_caseSubmit_case_sections {
  __typename: "CandidateDataSection";
  /**
   * Whether all questions in this section has been answered.
   */
  completed: boolean;
  section: Section;
  /**
   * Provides information for both questions and optional answers.
   * If some question allows multiple answers then several Answer structures come with the same questionId in this array.
   */
  answers: caseSubmit_caseSubmit_case_sections_answers[];
}

export interface caseSubmit_caseSubmit_case_contract_support {
  __typename: "CandidateContractSupport";
  supportEmailAddress: string;
  supportPhoneNumber: string;
}

export interface caseSubmit_caseSubmit_case_contract {
  __typename: "CandidateContract";
  id: string;
  support: caseSubmit_caseSubmit_case_contract_support;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction_values_title;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction_values[];
}

export type caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions = caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_DateRestriction | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_DurationRestriction | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_GapRestriction | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_LengthRestriction | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_NumericRestriction | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction;

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields = caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsOcrFieldValue;

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions[];
  description: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field_description;
  title: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field_title;
  opsFields: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields[];
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_LongValue {
  __typename: "LongValue";
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_Attachments {
  __typename: "Attachments";
  attachmentsValue: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_Attachments_attachmentsValue[];
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_Company {
  __typename: "Company";
  name: string;
  address: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_Company_address;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades[];
}

export type caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value = caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_LongValue | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_Address | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_Attachment | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_Attachments | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_BooleanValue | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_Company | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_Country | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_DateValue | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_FloatValue | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_StringValue | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_DateRange | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_StringValues | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades;

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue {
  __typename: "CandidateFieldValue";
  field: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_field;
  value: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue_value | null;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions {
  __typename: "CandidateFieldCondition";
  id: string | null;
  fieldValue: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions_fieldValue;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction_values_title;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction_values[];
}

export type caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields_restrictions = caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields_restrictions_DateRestriction | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields_restrictions_DurationRestriction | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields_restrictions_GapRestriction | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields_restrictions_LengthRestriction | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields_restrictions_NumericRestriction | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction;

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields_opsFields = caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields_opsFields_OpsEquifaxFieldValue | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields_opsFields_OpsEbulkFieldValue | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields_opsFields_OpsOcrFieldValue;

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields_restrictions[];
  description: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields_description;
  title: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields_title;
  opsFields: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields_opsFields[];
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction_values_title;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction_values[];
}

export type caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions = caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_DateRestriction | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_DurationRestriction | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_GapRestriction | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_LengthRestriction | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_NumericRestriction | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction;

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields = caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsEquifaxFieldValue | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsEbulkFieldValue | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsOcrFieldValue;

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions[];
  description: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields_description;
  title: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields_title;
  opsFields: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields[];
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values_title;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values[];
}

export type caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions = caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_DateRestriction | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_DurationRestriction | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_GapRestriction | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_LengthRestriction | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_NumericRestriction | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction;

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields = caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsOcrFieldValue;

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions[];
  description: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_description;
  title: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_title;
  opsFields: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields[];
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_LongValue {
  __typename: "LongValue";
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachments {
  __typename: "Attachments";
  attachmentsValue: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachments_attachmentsValue[];
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company {
  __typename: "Company";
  name: string;
  address: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company_address;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades[];
}

export type caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value = caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_LongValue | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Address | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachment | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachments | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_BooleanValue | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Country | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_DateValue | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_FloatValue | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_StringValue | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_DateRange | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_StringValues | caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades;

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue {
  __typename: "CandidateFieldValue";
  field: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field;
  value: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value | null;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions {
  __typename: "CandidateFieldCondition";
  id: string | null;
  fieldValue: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements {
  __typename: "CandidateQuestionConditionalFields";
  id: string | null;
  /**
   * Fields to be enabled when condition is true.
   */
  fields: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_fields[];
  conditions: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements_conditions[];
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question {
  __typename: "CandidateQuestion";
  id: string;
  /**
   *  TODO: check if sectionOrder is needed here
   * sectionOrder: CandidateSectionOrder!
   */
  conditional: boolean;
  /**
   * Whether the user can answer the question multiple times, like previous job
   */
  multipleAnswers: boolean;
  /**
   * Identity proof needs special wording/etc at the candidate portal.
   */
  questionType: QuestionType;
  title: string;
  fields: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_fields[];
  conditionalElements: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question_conditionalElements[];
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalQuestions {
  __typename: "CandidateProductConditionalQuestion";
  id: string | null;
  conditions: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_conditions[];
  /**
   * Questions to be enabled when condition is true.
   */
  question: caseSubmit_caseSubmit_case_products_product_conditionalQuestions_question;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_answersCondition {
  __typename: "CandidateAnswersCondition";
  maxNumberOfAnswers: number | null;
  minNumberOfAnswers: number | null;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction_values_title;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction_values[];
}

export type caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions = caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_DateRestriction | caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_DurationRestriction | caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_GapRestriction | caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_LengthRestriction | caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_NumericRestriction | caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction;

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields = caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue | caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue | caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsOcrFieldValue;

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions[];
  description: caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field_description;
  title: caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field_title;
  opsFields: caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields[];
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_LongValue {
  __typename: "LongValue";
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_Attachments {
  __typename: "Attachments";
  attachmentsValue: caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_Attachments_attachmentsValue[];
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_Company {
  __typename: "Company";
  name: string;
  address: caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_Company_address;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades[];
}

export type caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value = caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_LongValue | caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_Address | caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_Attachment | caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_Attachments | caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_BooleanValue | caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_Company | caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_Country | caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_DateValue | caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_FloatValue | caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_StringValue | caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_DateRange | caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_StringValues | caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades;

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue {
  __typename: "CandidateFieldValue";
  field: caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_field;
  value: caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue_value | null;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions {
  __typename: "CandidateFieldCondition";
  id: string | null;
  fieldValue: caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions_fieldValue;
}

export interface caseSubmit_caseSubmit_case_products_product_conditionalValidations {
  __typename: "CandidateProductConditionalValidation";
  id: string | null;
  validationMessage: string;
  answersCondition: caseSubmit_caseSubmit_case_products_product_conditionalValidations_answersCondition | null;
  conditions: caseSubmit_caseSubmit_case_products_product_conditionalValidations_conditions[];
}

export interface caseSubmit_caseSubmit_case_products_product {
  __typename: "CandidateProduct";
  id: string;
  conditionalQuestions: caseSubmit_caseSubmit_case_products_product_conditionalQuestions[];
  conditionalValidations: caseSubmit_caseSubmit_case_products_product_conditionalValidations[];
}

export interface caseSubmit_caseSubmit_case_products {
  __typename: "CandidateCaseProduct";
  id: string;
  status: CandidateCaseProductStatus;
  name: string;
  product: caseSubmit_caseSubmit_case_products_product;
}

export interface caseSubmit_caseSubmit_case {
  __typename: "CandidateCase";
  id: string;
  code: string;
  status: CandidateCaseStatus;
  /**
   * Assumption: deadline is hard-coded number of days for now
   */
  deadline: ScalarLong;
  ocrTriggered: boolean;
  additionalCandidateAttachments: caseSubmit_caseSubmit_case_additionalCandidateAttachments[];
  candidate: caseSubmit_caseSubmit_case_candidate;
  eSignature: caseSubmit_caseSubmit_case_eSignature | null;
  consentType: ConsentType;
  consentText: string | null;
  troubleshotByScreener: boolean;
  consentGiven: boolean;
  whenCreated: ScalarLong;
  whenCompleted: ScalarLong | null;
  trustIdApplication: caseSubmit_caseSubmit_case_trustIdApplication | null;
  draft: string | null;
  hasEnhancedCriminalityCheck: boolean;
  consentForm: caseSubmit_caseSubmit_case_consentForm | null;
  originalConsentForm: caseSubmit_caseSubmit_case_originalConsentForm | null;
  client: caseSubmit_caseSubmit_case_client;
  sectionsByAffectingField: caseSubmit_caseSubmit_case_sectionsByAffectingField[];
  sections: caseSubmit_caseSubmit_case_sections[];
  contract: caseSubmit_caseSubmit_case_contract;
  products: caseSubmit_caseSubmit_case_products[];
}

export interface caseSubmit_caseSubmit {
  __typename: "CandidateCaseSubmitPayload";
  case: caseSubmit_caseSubmit_case;
}

export interface caseSubmit {
  caseSubmit: caseSubmit_caseSubmit;
}

export interface caseSubmitVariables {
  input: CandidateCaseSubmitInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: doCaseTroubleshootingRequestUpdate
// ====================================================

export interface doCaseTroubleshootingRequestUpdate_caseTroubleshootingRequestUpdate_troubleshootingRequest {
  __typename: "CandidateCaseTroubleshootingRequest";
  id: string;
  status: TroubleshootingRequestStatus;
  createdWhen: ScalarLong;
}

export interface doCaseTroubleshootingRequestUpdate_caseTroubleshootingRequestUpdate {
  __typename: "CandidateCaseTroubleshootingRequestUpdatePayload";
  troubleshootingRequest: doCaseTroubleshootingRequestUpdate_caseTroubleshootingRequestUpdate_troubleshootingRequest;
}

export interface doCaseTroubleshootingRequestUpdate {
  caseTroubleshootingRequestUpdate: doCaseTroubleshootingRequestUpdate_caseTroubleshootingRequestUpdate;
}

export interface doCaseTroubleshootingRequestUpdateVariables {
  input: CandidateCaseTroubleshootingRequestUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: caseUpdate
// ====================================================

export interface caseUpdate_caseUpdate_case_additionalCandidateAttachments_candidateAttachments {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface caseUpdate_caseUpdate_case_additionalCandidateAttachments {
  __typename: "AdditionalCandidateAttachments";
  note: string | null;
  whenUploaded: ScalarLong;
  candidateAttachments: caseUpdate_caseUpdate_case_additionalCandidateAttachments_candidateAttachments[];
}

export interface caseUpdate_caseUpdate_case_candidate {
  __typename: "Candidate";
  firstName: string;
  lastName: string;
  emailAddress: string;
}

export interface caseUpdate_caseUpdate_case_eSignature {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface caseUpdate_caseUpdate_case_trustIdApplication_state {
  __typename: "TrustIdApplicationState";
  status: TrustIDApplicationStatus;
}

export interface caseUpdate_caseUpdate_case_trustIdApplication {
  __typename: "CandidateTrustIdApplication";
  state: caseUpdate_caseUpdate_case_trustIdApplication_state;
}

export interface caseUpdate_caseUpdate_case_consentForm {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface caseUpdate_caseUpdate_case_originalConsentForm {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface caseUpdate_caseUpdate_case_client_logo {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface caseUpdate_caseUpdate_case_client {
  __typename: "CandidateClient";
  id: string;
  name: string;
  liveChatEnabled: boolean;
  logo: caseUpdate_caseUpdate_case_client_logo | null;
}

export interface caseUpdate_caseUpdate_case_sectionsByAffectingField {
  __typename: "SectionsByAffectingField";
  conditionalProductsAffectedSections: Section[];
  fieldId: string;
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction_values_title;
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction_values[];
}

export type caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field_restrictions = caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field_restrictions_DateRestriction | caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field_restrictions_DurationRestriction | caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field_restrictions_GapRestriction | caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field_restrictions_LengthRestriction | caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field_restrictions_NumericRestriction | caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction;

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field_opsFields = caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field_opsFields_OpsEquifaxFieldValue | caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field_opsFields_OpsEbulkFieldValue | caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field_opsFields_OpsOcrFieldValue;

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field_restrictions[];
  description: caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field_description;
  title: caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field_title;
  opsFields: caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field_opsFields[];
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_LongValue {
  __typename: "LongValue";
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_Attachments {
  __typename: "Attachments";
  attachmentsValue: caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_Attachments_attachmentsValue[];
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_Company {
  __typename: "Company";
  name: string;
  address: caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_Company_address;
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_QualificationsAndGrades_qualificationsAndGrades[];
}

export type caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value = caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_LongValue | caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_Address | caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_Attachment | caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_Attachments | caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_BooleanValue | caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_Company | caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_Country | caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_DateValue | caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_FloatValue | caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_StringValue | caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_DateRange | caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_StringValues | caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value_QualificationsAndGrades;

export interface caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues {
  __typename: "CandidateFieldValue";
  skip: boolean;
  field: caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_field;
  value: caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues_value | null;
}

export interface caseUpdate_caseUpdate_case_sections_answers_answers {
  __typename: "CandidateAnswerDetails";
  isCandidateAnswer: boolean;
  id: string;
  fieldValues: caseUpdate_caseUpdate_case_sections_answers_answers_fieldValues[];
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_fields_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_fields_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_fields_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_fields_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_fields_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_fields_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_fields_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: caseUpdate_caseUpdate_case_sections_answers_question_fields_restrictions_SelectRestriction_values_title;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_fields_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: caseUpdate_caseUpdate_case_sections_answers_question_fields_restrictions_SelectRestriction_values[];
}

export type caseUpdate_caseUpdate_case_sections_answers_question_fields_restrictions = caseUpdate_caseUpdate_case_sections_answers_question_fields_restrictions_DateRestriction | caseUpdate_caseUpdate_case_sections_answers_question_fields_restrictions_DurationRestriction | caseUpdate_caseUpdate_case_sections_answers_question_fields_restrictions_GapRestriction | caseUpdate_caseUpdate_case_sections_answers_question_fields_restrictions_LengthRestriction | caseUpdate_caseUpdate_case_sections_answers_question_fields_restrictions_NumericRestriction | caseUpdate_caseUpdate_case_sections_answers_question_fields_restrictions_SelectRestriction;

export interface caseUpdate_caseUpdate_case_sections_answers_question_fields_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_fields_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_fields_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_fields_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_fields_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type caseUpdate_caseUpdate_case_sections_answers_question_fields_opsFields = caseUpdate_caseUpdate_case_sections_answers_question_fields_opsFields_OpsEquifaxFieldValue | caseUpdate_caseUpdate_case_sections_answers_question_fields_opsFields_OpsEbulkFieldValue | caseUpdate_caseUpdate_case_sections_answers_question_fields_opsFields_OpsOcrFieldValue;

export interface caseUpdate_caseUpdate_case_sections_answers_question_fields {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: caseUpdate_caseUpdate_case_sections_answers_question_fields_restrictions[];
  description: caseUpdate_caseUpdate_case_sections_answers_question_fields_description;
  title: caseUpdate_caseUpdate_case_sections_answers_question_fields_title;
  opsFields: caseUpdate_caseUpdate_case_sections_answers_question_fields_opsFields[];
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction_values_title;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction_values[];
}

export type caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields_restrictions = caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields_restrictions_DateRestriction | caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields_restrictions_DurationRestriction | caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields_restrictions_GapRestriction | caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields_restrictions_LengthRestriction | caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields_restrictions_NumericRestriction | caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction;

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields_opsFields = caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields_opsFields_OpsEquifaxFieldValue | caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields_opsFields_OpsEbulkFieldValue | caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields_opsFields_OpsOcrFieldValue;

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields_restrictions[];
  description: caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields_description;
  title: caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields_title;
  opsFields: caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields_opsFields[];
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values_title;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values[];
}

export type caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions = caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_DateRestriction | caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_DurationRestriction | caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_GapRestriction | caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_LengthRestriction | caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_NumericRestriction | caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction;

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields = caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue | caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue | caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsOcrFieldValue;

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions[];
  description: caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_description;
  title: caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_title;
  opsFields: caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields[];
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_LongValue {
  __typename: "LongValue";
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachments {
  __typename: "Attachments";
  attachmentsValue: caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachments_attachmentsValue[];
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company {
  __typename: "Company";
  name: string;
  address: caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company_address;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades[];
}

export type caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value = caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_LongValue | caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Address | caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachment | caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachments | caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_BooleanValue | caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company | caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_Country | caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_DateValue | caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_FloatValue | caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_StringValue | caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_DateRange | caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_StringValues | caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades;

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue {
  __typename: "CandidateFieldValue";
  field: caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_field;
  value: caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue_value | null;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions {
  __typename: "CandidateFieldCondition";
  id: string | null;
  fieldValue: caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions_fieldValue;
}

export interface caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements {
  __typename: "CandidateQuestionConditionalFields";
  id: string | null;
  /**
   * Fields to be enabled when condition is true.
   */
  fields: caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_fields[];
  conditions: caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements_conditions[];
}

export interface caseUpdate_caseUpdate_case_sections_answers_question {
  __typename: "CandidateQuestion";
  id: string;
  /**
   *  TODO: check if sectionOrder is needed here
   * sectionOrder: CandidateSectionOrder!
   */
  conditional: boolean;
  /**
   * Whether the user can answer the question multiple times, like previous job
   */
  multipleAnswers: boolean;
  /**
   * Identity proof needs special wording/etc at the candidate portal.
   */
  questionType: QuestionType;
  title: string;
  fields: caseUpdate_caseUpdate_case_sections_answers_question_fields[];
  conditionalElements: caseUpdate_caseUpdate_case_sections_answers_question_conditionalElements[];
}

export interface caseUpdate_caseUpdate_case_sections_answers {
  __typename: "CandidateAnswer";
  conditionFulfilled: boolean;
  answers: caseUpdate_caseUpdate_case_sections_answers_answers[];
  question: caseUpdate_caseUpdate_case_sections_answers_question;
}

export interface caseUpdate_caseUpdate_case_sections {
  __typename: "CandidateDataSection";
  /**
   * Whether all questions in this section has been answered.
   */
  completed: boolean;
  section: Section;
  /**
   * Provides information for both questions and optional answers.
   * If some question allows multiple answers then several Answer structures come with the same questionId in this array.
   */
  answers: caseUpdate_caseUpdate_case_sections_answers[];
}

export interface caseUpdate_caseUpdate_case_contract_support {
  __typename: "CandidateContractSupport";
  supportEmailAddress: string;
  supportPhoneNumber: string;
}

export interface caseUpdate_caseUpdate_case_contract {
  __typename: "CandidateContract";
  id: string;
  support: caseUpdate_caseUpdate_case_contract_support;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction_values_title;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction_values[];
}

export type caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions = caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_DateRestriction | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_DurationRestriction | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_GapRestriction | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_LengthRestriction | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_NumericRestriction | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction;

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields = caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsOcrFieldValue;

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions[];
  description: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field_description;
  title: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field_title;
  opsFields: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields[];
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_LongValue {
  __typename: "LongValue";
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_Attachments {
  __typename: "Attachments";
  attachmentsValue: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_Attachments_attachmentsValue[];
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_Company {
  __typename: "Company";
  name: string;
  address: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_Company_address;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades[];
}

export type caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value = caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_LongValue | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_Address | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_Attachment | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_Attachments | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_BooleanValue | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_Company | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_Country | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_DateValue | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_FloatValue | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_StringValue | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_DateRange | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_StringValues | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades;

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue {
  __typename: "CandidateFieldValue";
  field: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_field;
  value: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue_value | null;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions {
  __typename: "CandidateFieldCondition";
  id: string | null;
  fieldValue: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions_fieldValue;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction_values_title;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction_values[];
}

export type caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields_restrictions = caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields_restrictions_DateRestriction | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields_restrictions_DurationRestriction | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields_restrictions_GapRestriction | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields_restrictions_LengthRestriction | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields_restrictions_NumericRestriction | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction;

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields_opsFields = caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields_opsFields_OpsEquifaxFieldValue | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields_opsFields_OpsEbulkFieldValue | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields_opsFields_OpsOcrFieldValue;

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields_restrictions[];
  description: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields_description;
  title: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields_title;
  opsFields: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields_opsFields[];
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction_values_title;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction_values[];
}

export type caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions = caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_DateRestriction | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_DurationRestriction | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_GapRestriction | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_LengthRestriction | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_NumericRestriction | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction;

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields = caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsEquifaxFieldValue | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsEbulkFieldValue | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsOcrFieldValue;

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions[];
  description: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields_description;
  title: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields_title;
  opsFields: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields[];
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values_title;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values[];
}

export type caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions = caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_DateRestriction | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_DurationRestriction | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_GapRestriction | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_LengthRestriction | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_NumericRestriction | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction;

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields = caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsOcrFieldValue;

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions[];
  description: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_description;
  title: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_title;
  opsFields: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields[];
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_LongValue {
  __typename: "LongValue";
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachments {
  __typename: "Attachments";
  attachmentsValue: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachments_attachmentsValue[];
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company {
  __typename: "Company";
  name: string;
  address: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company_address;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades[];
}

export type caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value = caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_LongValue | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Address | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachment | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachments | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_BooleanValue | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Country | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_DateValue | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_FloatValue | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_StringValue | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_DateRange | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_StringValues | caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades;

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue {
  __typename: "CandidateFieldValue";
  field: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field;
  value: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value | null;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions {
  __typename: "CandidateFieldCondition";
  id: string | null;
  fieldValue: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements {
  __typename: "CandidateQuestionConditionalFields";
  id: string | null;
  /**
   * Fields to be enabled when condition is true.
   */
  fields: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_fields[];
  conditions: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements_conditions[];
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question {
  __typename: "CandidateQuestion";
  id: string;
  /**
   *  TODO: check if sectionOrder is needed here
   * sectionOrder: CandidateSectionOrder!
   */
  conditional: boolean;
  /**
   * Whether the user can answer the question multiple times, like previous job
   */
  multipleAnswers: boolean;
  /**
   * Identity proof needs special wording/etc at the candidate portal.
   */
  questionType: QuestionType;
  title: string;
  fields: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_fields[];
  conditionalElements: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question_conditionalElements[];
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalQuestions {
  __typename: "CandidateProductConditionalQuestion";
  id: string | null;
  conditions: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_conditions[];
  /**
   * Questions to be enabled when condition is true.
   */
  question: caseUpdate_caseUpdate_case_products_product_conditionalQuestions_question;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_answersCondition {
  __typename: "CandidateAnswersCondition";
  maxNumberOfAnswers: number | null;
  minNumberOfAnswers: number | null;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction_values_title;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction_values[];
}

export type caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions = caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_DateRestriction | caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_DurationRestriction | caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_GapRestriction | caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_LengthRestriction | caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_NumericRestriction | caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction;

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields = caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue | caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue | caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsOcrFieldValue;

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field_restrictions[];
  description: caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field_description;
  title: caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field_title;
  opsFields: caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field_opsFields[];
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_LongValue {
  __typename: "LongValue";
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_Attachments {
  __typename: "Attachments";
  attachmentsValue: caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_Attachments_attachmentsValue[];
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_Company {
  __typename: "Company";
  name: string;
  address: caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_Company_address;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades[];
}

export type caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value = caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_LongValue | caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_Address | caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_Attachment | caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_Attachments | caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_BooleanValue | caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_Company | caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_Country | caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_DateValue | caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_FloatValue | caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_StringValue | caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_DateRange | caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_StringValues | caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades;

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue {
  __typename: "CandidateFieldValue";
  field: caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_field;
  value: caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue_value | null;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions {
  __typename: "CandidateFieldCondition";
  id: string | null;
  fieldValue: caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions_fieldValue;
}

export interface caseUpdate_caseUpdate_case_products_product_conditionalValidations {
  __typename: "CandidateProductConditionalValidation";
  id: string | null;
  validationMessage: string;
  answersCondition: caseUpdate_caseUpdate_case_products_product_conditionalValidations_answersCondition | null;
  conditions: caseUpdate_caseUpdate_case_products_product_conditionalValidations_conditions[];
}

export interface caseUpdate_caseUpdate_case_products_product {
  __typename: "CandidateProduct";
  id: string;
  conditionalQuestions: caseUpdate_caseUpdate_case_products_product_conditionalQuestions[];
  conditionalValidations: caseUpdate_caseUpdate_case_products_product_conditionalValidations[];
}

export interface caseUpdate_caseUpdate_case_products {
  __typename: "CandidateCaseProduct";
  id: string;
  status: CandidateCaseProductStatus;
  name: string;
  product: caseUpdate_caseUpdate_case_products_product;
}

export interface caseUpdate_caseUpdate_case {
  __typename: "CandidateCase";
  id: string;
  code: string;
  status: CandidateCaseStatus;
  /**
   * Assumption: deadline is hard-coded number of days for now
   */
  deadline: ScalarLong;
  ocrTriggered: boolean;
  additionalCandidateAttachments: caseUpdate_caseUpdate_case_additionalCandidateAttachments[];
  candidate: caseUpdate_caseUpdate_case_candidate;
  eSignature: caseUpdate_caseUpdate_case_eSignature | null;
  consentType: ConsentType;
  consentText: string | null;
  troubleshotByScreener: boolean;
  consentGiven: boolean;
  whenCreated: ScalarLong;
  whenCompleted: ScalarLong | null;
  trustIdApplication: caseUpdate_caseUpdate_case_trustIdApplication | null;
  draft: string | null;
  hasEnhancedCriminalityCheck: boolean;
  consentForm: caseUpdate_caseUpdate_case_consentForm | null;
  originalConsentForm: caseUpdate_caseUpdate_case_originalConsentForm | null;
  client: caseUpdate_caseUpdate_case_client;
  sectionsByAffectingField: caseUpdate_caseUpdate_case_sectionsByAffectingField[];
  sections: caseUpdate_caseUpdate_case_sections[];
  contract: caseUpdate_caseUpdate_case_contract;
  products: caseUpdate_caseUpdate_case_products[];
}

export interface caseUpdate_caseUpdate {
  __typename: "CandidateCaseUpdatePayload";
  case: caseUpdate_caseUpdate_case;
}

export interface caseUpdate {
  caseUpdate: caseUpdate_caseUpdate;
}

export interface caseUpdateVariables {
  files?: ScalarUpload[] | null;
  input: CandidateCaseUpdateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: KonfirVerificationLinkCreate
// ====================================================

export interface KonfirVerificationLinkCreate_konfirVerificationLinkCreate {
  __typename: "CandidateKonfirVerificationLinkCreatePayload";
  verificationLink: string;
}

export interface KonfirVerificationLinkCreate {
  konfirVerificationLinkCreate: KonfirVerificationLinkCreate_konfirVerificationLinkCreate;
}

export interface KonfirVerificationLinkCreateVariables {
  input: CandidateKonfirVerificationLinkCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createTrustIdGuestLink
// ====================================================

export interface createTrustIdGuestLink_trustIdGuestLinkCreate {
  __typename: "CandidateTrustIdGuestLinkCreatePayload";
  guestLink: string;
}

export interface createTrustIdGuestLink {
  trustIdGuestLinkCreate: createTrustIdGuestLink_trustIdGuestLinkCreate;
}

export interface createTrustIdGuestLinkVariables {
  input: CandidateTrustIdGuestLinkCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: additionalDocumentsUpload
// ====================================================

export interface additionalDocumentsUpload_uploadAdditionalDocuments {
  __typename: "CandidateUploadAdditionalDocumentPayload";
  screeningCaseId: string;
}

export interface additionalDocumentsUpload {
  uploadAdditionalDocuments: additionalDocumentsUpload_uploadAdditionalDocuments;
}

export interface additionalDocumentsUploadVariables {
  files: ScalarUpload[];
  input: CandidateUploadAdditionalDocumentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getCase
// ====================================================

export interface getCase_screeningCase_additionalCandidateAttachments_candidateAttachments {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface getCase_screeningCase_additionalCandidateAttachments {
  __typename: "AdditionalCandidateAttachments";
  note: string | null;
  whenUploaded: ScalarLong;
  candidateAttachments: getCase_screeningCase_additionalCandidateAttachments_candidateAttachments[];
}

export interface getCase_screeningCase_candidate {
  __typename: "Candidate";
  firstName: string;
  lastName: string;
  emailAddress: string;
}

export interface getCase_screeningCase_eSignature {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface getCase_screeningCase_trustIdApplication_state {
  __typename: "TrustIdApplicationState";
  status: TrustIDApplicationStatus;
}

export interface getCase_screeningCase_trustIdApplication {
  __typename: "CandidateTrustIdApplication";
  state: getCase_screeningCase_trustIdApplication_state;
}

export interface getCase_screeningCase_consentForm {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface getCase_screeningCase_originalConsentForm {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface getCase_screeningCase_client_logo {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface getCase_screeningCase_client {
  __typename: "CandidateClient";
  id: string;
  name: string;
  liveChatEnabled: boolean;
  logo: getCase_screeningCase_client_logo | null;
}

export interface getCase_screeningCase_sectionsByAffectingField {
  __typename: "SectionsByAffectingField";
  conditionalProductsAffectedSections: Section[];
  fieldId: string;
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_field_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_field_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_field_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_field_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_field_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: getCase_screeningCase_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction_values_title;
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: getCase_screeningCase_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction_values[];
}

export type getCase_screeningCase_sections_answers_answers_fieldValues_field_restrictions = getCase_screeningCase_sections_answers_answers_fieldValues_field_restrictions_DateRestriction | getCase_screeningCase_sections_answers_answers_fieldValues_field_restrictions_DurationRestriction | getCase_screeningCase_sections_answers_answers_fieldValues_field_restrictions_GapRestriction | getCase_screeningCase_sections_answers_answers_fieldValues_field_restrictions_LengthRestriction | getCase_screeningCase_sections_answers_answers_fieldValues_field_restrictions_NumericRestriction | getCase_screeningCase_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction;

export interface getCase_screeningCase_sections_answers_answers_fieldValues_field_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_field_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_field_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_field_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_field_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type getCase_screeningCase_sections_answers_answers_fieldValues_field_opsFields = getCase_screeningCase_sections_answers_answers_fieldValues_field_opsFields_OpsEquifaxFieldValue | getCase_screeningCase_sections_answers_answers_fieldValues_field_opsFields_OpsEbulkFieldValue | getCase_screeningCase_sections_answers_answers_fieldValues_field_opsFields_OpsOcrFieldValue;

export interface getCase_screeningCase_sections_answers_answers_fieldValues_field {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: getCase_screeningCase_sections_answers_answers_fieldValues_field_restrictions[];
  description: getCase_screeningCase_sections_answers_answers_fieldValues_field_description;
  title: getCase_screeningCase_sections_answers_answers_fieldValues_field_title;
  opsFields: getCase_screeningCase_sections_answers_answers_fieldValues_field_opsFields[];
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_value_LongValue {
  __typename: "LongValue";
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_value_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_value_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: getCase_screeningCase_sections_answers_answers_fieldValues_value_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_value_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_value_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_value_Attachments {
  __typename: "Attachments";
  attachmentsValue: getCase_screeningCase_sections_answers_answers_fieldValues_value_Attachments_attachmentsValue[];
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_value_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_value_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_value_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: getCase_screeningCase_sections_answers_answers_fieldValues_value_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_value_Company {
  __typename: "Company";
  name: string;
  address: getCase_screeningCase_sections_answers_answers_fieldValues_value_Company_address;
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_value_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_value_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_value_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_value_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_value_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_value_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_value_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_value_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: getCase_screeningCase_sections_answers_answers_fieldValues_value_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface getCase_screeningCase_sections_answers_answers_fieldValues_value_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: getCase_screeningCase_sections_answers_answers_fieldValues_value_QualificationsAndGrades_qualificationsAndGrades[];
}

export type getCase_screeningCase_sections_answers_answers_fieldValues_value = getCase_screeningCase_sections_answers_answers_fieldValues_value_LongValue | getCase_screeningCase_sections_answers_answers_fieldValues_value_Address | getCase_screeningCase_sections_answers_answers_fieldValues_value_Attachment | getCase_screeningCase_sections_answers_answers_fieldValues_value_Attachments | getCase_screeningCase_sections_answers_answers_fieldValues_value_BooleanValue | getCase_screeningCase_sections_answers_answers_fieldValues_value_Company | getCase_screeningCase_sections_answers_answers_fieldValues_value_Country | getCase_screeningCase_sections_answers_answers_fieldValues_value_DateValue | getCase_screeningCase_sections_answers_answers_fieldValues_value_FloatValue | getCase_screeningCase_sections_answers_answers_fieldValues_value_StringValue | getCase_screeningCase_sections_answers_answers_fieldValues_value_DateRange | getCase_screeningCase_sections_answers_answers_fieldValues_value_StringValues | getCase_screeningCase_sections_answers_answers_fieldValues_value_QualificationsAndGrades;

export interface getCase_screeningCase_sections_answers_answers_fieldValues {
  __typename: "CandidateFieldValue";
  skip: boolean;
  field: getCase_screeningCase_sections_answers_answers_fieldValues_field;
  value: getCase_screeningCase_sections_answers_answers_fieldValues_value | null;
}

export interface getCase_screeningCase_sections_answers_answers {
  __typename: "CandidateAnswerDetails";
  isCandidateAnswer: boolean;
  id: string;
  fieldValues: getCase_screeningCase_sections_answers_answers_fieldValues[];
}

export interface getCase_screeningCase_sections_answers_question_fields_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface getCase_screeningCase_sections_answers_question_fields_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface getCase_screeningCase_sections_answers_question_fields_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface getCase_screeningCase_sections_answers_question_fields_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface getCase_screeningCase_sections_answers_question_fields_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface getCase_screeningCase_sections_answers_question_fields_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface getCase_screeningCase_sections_answers_question_fields_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: getCase_screeningCase_sections_answers_question_fields_restrictions_SelectRestriction_values_title;
}

export interface getCase_screeningCase_sections_answers_question_fields_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: getCase_screeningCase_sections_answers_question_fields_restrictions_SelectRestriction_values[];
}

export type getCase_screeningCase_sections_answers_question_fields_restrictions = getCase_screeningCase_sections_answers_question_fields_restrictions_DateRestriction | getCase_screeningCase_sections_answers_question_fields_restrictions_DurationRestriction | getCase_screeningCase_sections_answers_question_fields_restrictions_GapRestriction | getCase_screeningCase_sections_answers_question_fields_restrictions_LengthRestriction | getCase_screeningCase_sections_answers_question_fields_restrictions_NumericRestriction | getCase_screeningCase_sections_answers_question_fields_restrictions_SelectRestriction;

export interface getCase_screeningCase_sections_answers_question_fields_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface getCase_screeningCase_sections_answers_question_fields_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface getCase_screeningCase_sections_answers_question_fields_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface getCase_screeningCase_sections_answers_question_fields_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface getCase_screeningCase_sections_answers_question_fields_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type getCase_screeningCase_sections_answers_question_fields_opsFields = getCase_screeningCase_sections_answers_question_fields_opsFields_OpsEquifaxFieldValue | getCase_screeningCase_sections_answers_question_fields_opsFields_OpsEbulkFieldValue | getCase_screeningCase_sections_answers_question_fields_opsFields_OpsOcrFieldValue;

export interface getCase_screeningCase_sections_answers_question_fields {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: getCase_screeningCase_sections_answers_question_fields_restrictions[];
  description: getCase_screeningCase_sections_answers_question_fields_description;
  title: getCase_screeningCase_sections_answers_question_fields_title;
  opsFields: getCase_screeningCase_sections_answers_question_fields_opsFields[];
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_fields_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_fields_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_fields_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_fields_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_fields_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: getCase_screeningCase_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction_values_title;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: getCase_screeningCase_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction_values[];
}

export type getCase_screeningCase_sections_answers_question_conditionalElements_fields_restrictions = getCase_screeningCase_sections_answers_question_conditionalElements_fields_restrictions_DateRestriction | getCase_screeningCase_sections_answers_question_conditionalElements_fields_restrictions_DurationRestriction | getCase_screeningCase_sections_answers_question_conditionalElements_fields_restrictions_GapRestriction | getCase_screeningCase_sections_answers_question_conditionalElements_fields_restrictions_LengthRestriction | getCase_screeningCase_sections_answers_question_conditionalElements_fields_restrictions_NumericRestriction | getCase_screeningCase_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction;

export interface getCase_screeningCase_sections_answers_question_conditionalElements_fields_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_fields_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_fields_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_fields_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_fields_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type getCase_screeningCase_sections_answers_question_conditionalElements_fields_opsFields = getCase_screeningCase_sections_answers_question_conditionalElements_fields_opsFields_OpsEquifaxFieldValue | getCase_screeningCase_sections_answers_question_conditionalElements_fields_opsFields_OpsEbulkFieldValue | getCase_screeningCase_sections_answers_question_conditionalElements_fields_opsFields_OpsOcrFieldValue;

export interface getCase_screeningCase_sections_answers_question_conditionalElements_fields {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: getCase_screeningCase_sections_answers_question_conditionalElements_fields_restrictions[];
  description: getCase_screeningCase_sections_answers_question_conditionalElements_fields_description;
  title: getCase_screeningCase_sections_answers_question_conditionalElements_fields_title;
  opsFields: getCase_screeningCase_sections_answers_question_conditionalElements_fields_opsFields[];
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values_title;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values[];
}

export type getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions = getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_DateRestriction | getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_DurationRestriction | getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_GapRestriction | getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_LengthRestriction | getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_NumericRestriction | getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction;

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields = getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue | getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue | getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsOcrFieldValue;

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions[];
  description: getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field_description;
  title: getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field_title;
  opsFields: getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields[];
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_LongValue {
  __typename: "LongValue";
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachments {
  __typename: "Attachments";
  attachmentsValue: getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachments_attachmentsValue[];
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company {
  __typename: "Company";
  name: string;
  address: getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company_address;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades[];
}

export type getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value = getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_LongValue | getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_Address | getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachment | getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachments | getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_BooleanValue | getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company | getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_Country | getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_DateValue | getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_FloatValue | getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_StringValue | getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_DateRange | getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_StringValues | getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades;

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue {
  __typename: "CandidateFieldValue";
  field: getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_field;
  value: getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue_value | null;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements_conditions {
  __typename: "CandidateFieldCondition";
  id: string | null;
  fieldValue: getCase_screeningCase_sections_answers_question_conditionalElements_conditions_fieldValue;
}

export interface getCase_screeningCase_sections_answers_question_conditionalElements {
  __typename: "CandidateQuestionConditionalFields";
  id: string | null;
  /**
   * Fields to be enabled when condition is true.
   */
  fields: getCase_screeningCase_sections_answers_question_conditionalElements_fields[];
  conditions: getCase_screeningCase_sections_answers_question_conditionalElements_conditions[];
}

export interface getCase_screeningCase_sections_answers_question {
  __typename: "CandidateQuestion";
  id: string;
  /**
   *  TODO: check if sectionOrder is needed here
   * sectionOrder: CandidateSectionOrder!
   */
  conditional: boolean;
  /**
   * Whether the user can answer the question multiple times, like previous job
   */
  multipleAnswers: boolean;
  /**
   * Identity proof needs special wording/etc at the candidate portal.
   */
  questionType: QuestionType;
  title: string;
  fields: getCase_screeningCase_sections_answers_question_fields[];
  conditionalElements: getCase_screeningCase_sections_answers_question_conditionalElements[];
}

export interface getCase_screeningCase_sections_answers {
  __typename: "CandidateAnswer";
  conditionFulfilled: boolean;
  answers: getCase_screeningCase_sections_answers_answers[];
  question: getCase_screeningCase_sections_answers_question;
}

export interface getCase_screeningCase_sections {
  __typename: "CandidateDataSection";
  /**
   * Whether all questions in this section has been answered.
   */
  completed: boolean;
  section: Section;
  /**
   * Provides information for both questions and optional answers.
   * If some question allows multiple answers then several Answer structures come with the same questionId in this array.
   */
  answers: getCase_screeningCase_sections_answers[];
}

export interface getCase_screeningCase_contract_support {
  __typename: "CandidateContractSupport";
  supportEmailAddress: string;
  supportPhoneNumber: string;
}

export interface getCase_screeningCase_contract {
  __typename: "CandidateContract";
  id: string;
  support: getCase_screeningCase_contract_support;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction_values_title;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction_values[];
}

export type getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions = getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_DateRestriction | getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_DurationRestriction | getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_GapRestriction | getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_LengthRestriction | getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_NumericRestriction | getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction;

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields = getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue | getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue | getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsOcrFieldValue;

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions[];
  description: getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field_description;
  title: getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field_title;
  opsFields: getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields[];
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_LongValue {
  __typename: "LongValue";
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_Attachments {
  __typename: "Attachments";
  attachmentsValue: getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_Attachments_attachmentsValue[];
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_Company {
  __typename: "Company";
  name: string;
  address: getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_Company_address;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades[];
}

export type getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value = getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_LongValue | getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_Address | getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_Attachment | getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_Attachments | getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_BooleanValue | getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_Company | getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_Country | getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_DateValue | getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_FloatValue | getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_StringValue | getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_DateRange | getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_StringValues | getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades;

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue {
  __typename: "CandidateFieldValue";
  field: getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_field;
  value: getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue_value | null;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_conditions {
  __typename: "CandidateFieldCondition";
  id: string | null;
  fieldValue: getCase_screeningCase_products_product_conditionalQuestions_conditions_fieldValue;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_fields_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_fields_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_fields_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_fields_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_fields_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: getCase_screeningCase_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction_values_title;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: getCase_screeningCase_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction_values[];
}

export type getCase_screeningCase_products_product_conditionalQuestions_question_fields_restrictions = getCase_screeningCase_products_product_conditionalQuestions_question_fields_restrictions_DateRestriction | getCase_screeningCase_products_product_conditionalQuestions_question_fields_restrictions_DurationRestriction | getCase_screeningCase_products_product_conditionalQuestions_question_fields_restrictions_GapRestriction | getCase_screeningCase_products_product_conditionalQuestions_question_fields_restrictions_LengthRestriction | getCase_screeningCase_products_product_conditionalQuestions_question_fields_restrictions_NumericRestriction | getCase_screeningCase_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction;

export interface getCase_screeningCase_products_product_conditionalQuestions_question_fields_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_fields_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_fields_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_fields_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_fields_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type getCase_screeningCase_products_product_conditionalQuestions_question_fields_opsFields = getCase_screeningCase_products_product_conditionalQuestions_question_fields_opsFields_OpsEquifaxFieldValue | getCase_screeningCase_products_product_conditionalQuestions_question_fields_opsFields_OpsEbulkFieldValue | getCase_screeningCase_products_product_conditionalQuestions_question_fields_opsFields_OpsOcrFieldValue;

export interface getCase_screeningCase_products_product_conditionalQuestions_question_fields {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: getCase_screeningCase_products_product_conditionalQuestions_question_fields_restrictions[];
  description: getCase_screeningCase_products_product_conditionalQuestions_question_fields_description;
  title: getCase_screeningCase_products_product_conditionalQuestions_question_fields_title;
  opsFields: getCase_screeningCase_products_product_conditionalQuestions_question_fields_opsFields[];
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction_values_title;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction_values[];
}

export type getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions = getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_DateRestriction | getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_DurationRestriction | getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_GapRestriction | getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_LengthRestriction | getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_NumericRestriction | getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction;

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields = getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsEquifaxFieldValue | getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsEbulkFieldValue | getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsOcrFieldValue;

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions[];
  description: getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields_description;
  title: getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields_title;
  opsFields: getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields[];
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values_title;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values[];
}

export type getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions = getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_DateRestriction | getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_DurationRestriction | getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_GapRestriction | getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_LengthRestriction | getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_NumericRestriction | getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction;

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields = getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue | getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue | getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsOcrFieldValue;

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions[];
  description: getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_description;
  title: getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_title;
  opsFields: getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields[];
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_LongValue {
  __typename: "LongValue";
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachments {
  __typename: "Attachments";
  attachmentsValue: getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachments_attachmentsValue[];
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company {
  __typename: "Company";
  name: string;
  address: getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company_address;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades[];
}

export type getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value = getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_LongValue | getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Address | getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachment | getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachments | getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_BooleanValue | getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company | getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Country | getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_DateValue | getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_FloatValue | getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_StringValue | getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_DateRange | getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_StringValues | getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades;

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue {
  __typename: "CandidateFieldValue";
  field: getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field;
  value: getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value | null;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions {
  __typename: "CandidateFieldCondition";
  id: string | null;
  fieldValue: getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue;
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements {
  __typename: "CandidateQuestionConditionalFields";
  id: string | null;
  /**
   * Fields to be enabled when condition is true.
   */
  fields: getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_fields[];
  conditions: getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements_conditions[];
}

export interface getCase_screeningCase_products_product_conditionalQuestions_question {
  __typename: "CandidateQuestion";
  id: string;
  /**
   *  TODO: check if sectionOrder is needed here
   * sectionOrder: CandidateSectionOrder!
   */
  conditional: boolean;
  /**
   * Whether the user can answer the question multiple times, like previous job
   */
  multipleAnswers: boolean;
  /**
   * Identity proof needs special wording/etc at the candidate portal.
   */
  questionType: QuestionType;
  title: string;
  fields: getCase_screeningCase_products_product_conditionalQuestions_question_fields[];
  conditionalElements: getCase_screeningCase_products_product_conditionalQuestions_question_conditionalElements[];
}

export interface getCase_screeningCase_products_product_conditionalQuestions {
  __typename: "CandidateProductConditionalQuestion";
  id: string | null;
  conditions: getCase_screeningCase_products_product_conditionalQuestions_conditions[];
  /**
   * Questions to be enabled when condition is true.
   */
  question: getCase_screeningCase_products_product_conditionalQuestions_question;
}

export interface getCase_screeningCase_products_product_conditionalValidations_answersCondition {
  __typename: "CandidateAnswersCondition";
  maxNumberOfAnswers: number | null;
  minNumberOfAnswers: number | null;
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction_values_title;
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction_values[];
}

export type getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field_restrictions = getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_DateRestriction | getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_DurationRestriction | getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_GapRestriction | getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_LengthRestriction | getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_NumericRestriction | getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction;

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field_opsFields = getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue | getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue | getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsOcrFieldValue;

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field_restrictions[];
  description: getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field_description;
  title: getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field_title;
  opsFields: getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field_opsFields[];
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_LongValue {
  __typename: "LongValue";
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_Attachments {
  __typename: "Attachments";
  attachmentsValue: getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_Attachments_attachmentsValue[];
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_Company {
  __typename: "Company";
  name: string;
  address: getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_Company_address;
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades[];
}

export type getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value = getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_LongValue | getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_Address | getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_Attachment | getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_Attachments | getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_BooleanValue | getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_Company | getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_Country | getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_DateValue | getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_FloatValue | getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_StringValue | getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_DateRange | getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_StringValues | getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades;

export interface getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue {
  __typename: "CandidateFieldValue";
  field: getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_field;
  value: getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue_value | null;
}

export interface getCase_screeningCase_products_product_conditionalValidations_conditions {
  __typename: "CandidateFieldCondition";
  id: string | null;
  fieldValue: getCase_screeningCase_products_product_conditionalValidations_conditions_fieldValue;
}

export interface getCase_screeningCase_products_product_conditionalValidations {
  __typename: "CandidateProductConditionalValidation";
  id: string | null;
  validationMessage: string;
  answersCondition: getCase_screeningCase_products_product_conditionalValidations_answersCondition | null;
  conditions: getCase_screeningCase_products_product_conditionalValidations_conditions[];
}

export interface getCase_screeningCase_products_product {
  __typename: "CandidateProduct";
  id: string;
  conditionalQuestions: getCase_screeningCase_products_product_conditionalQuestions[];
  conditionalValidations: getCase_screeningCase_products_product_conditionalValidations[];
}

export interface getCase_screeningCase_products {
  __typename: "CandidateCaseProduct";
  id: string;
  status: CandidateCaseProductStatus;
  name: string;
  product: getCase_screeningCase_products_product;
}

export interface getCase_screeningCase {
  __typename: "CandidateCase";
  id: string;
  code: string;
  status: CandidateCaseStatus;
  /**
   * Assumption: deadline is hard-coded number of days for now
   */
  deadline: ScalarLong;
  ocrTriggered: boolean;
  additionalCandidateAttachments: getCase_screeningCase_additionalCandidateAttachments[];
  candidate: getCase_screeningCase_candidate;
  eSignature: getCase_screeningCase_eSignature | null;
  consentType: ConsentType;
  consentText: string | null;
  troubleshotByScreener: boolean;
  consentGiven: boolean;
  whenCreated: ScalarLong;
  whenCompleted: ScalarLong | null;
  trustIdApplication: getCase_screeningCase_trustIdApplication | null;
  draft: string | null;
  hasEnhancedCriminalityCheck: boolean;
  consentForm: getCase_screeningCase_consentForm | null;
  originalConsentForm: getCase_screeningCase_originalConsentForm | null;
  client: getCase_screeningCase_client;
  sectionsByAffectingField: getCase_screeningCase_sectionsByAffectingField[];
  sections: getCase_screeningCase_sections[];
  contract: getCase_screeningCase_contract;
  products: getCase_screeningCase_products[];
}

export interface getCase {
  /**
   * Pending case to be filled by candidate.
   */
  screeningCase: getCase_screeningCase;
}

export interface getCaseVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getActiveTroubleShootingRequest
// ====================================================

export interface getActiveTroubleShootingRequest_activeCaseTroubleshootingRequest_troubleshootingRequest {
  __typename: "CandidateCaseTroubleshootingRequest";
  id: string;
  status: TroubleshootingRequestStatus;
  createdWhen: ScalarLong;
}

export interface getActiveTroubleShootingRequest_activeCaseTroubleshootingRequest {
  __typename: "CandidateCaseTroubleshootingRequestConnection";
  troubleshootingRequest: getActiveTroubleShootingRequest_activeCaseTroubleshootingRequest_troubleshootingRequest | null;
}

export interface getActiveTroubleShootingRequest {
  activeCaseTroubleshootingRequest: getActiveTroubleShootingRequest_activeCaseTroubleshootingRequest;
}

export interface getActiveTroubleShootingRequestVariables {
  caseId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Countries
// ====================================================

export interface Countries_countries {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface Countries {
  countries: Countries_countries[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAuthFlow
// ====================================================

export interface GetAuthFlow_authFlow {
  __typename: "CandidatePortalAuthFlowPayload";
  authFlow: CandidatePortalAuthFlow;
}

export interface GetAuthFlow {
  authFlow: GetAuthFlow_authFlow;
}

export interface GetAuthFlowVariables {
  caseId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getScreeningCases
// ====================================================

export interface getScreeningCases_screeningCases {
  __typename: "CandidateCase";
  id: string;
  whenCreated: ScalarLong;
}

export interface getScreeningCases {
  /**
   * Pending cases to be filled by candidate.
   */
  screeningCases: getScreeningCases_screeningCases[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: KonfirVerificationSubmittedMutation
// ====================================================

export interface KonfirVerificationSubmittedMutation_konfirVerificationSubmitted {
  __typename: "CandidateCase";
  id: string;
}

export interface KonfirVerificationSubmittedMutation {
  konfirVerificationSubmitted: KonfirVerificationSubmittedMutation_konfirVerificationSubmitted;
}

export interface KonfirVerificationSubmittedMutationVariables {
  caseId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: respondToDataRequest
// ====================================================

export interface respondToDataRequest_respondToDataRequest_product {
  __typename: "CandidateCaseProduct";
  id: string;
}

export interface respondToDataRequest_respondToDataRequest {
  __typename: "RespondToCandidateDataRequestPayload";
  product: respondToDataRequest_respondToDataRequest_product;
}

export interface respondToDataRequest {
  respondToDataRequest: respondToDataRequest_respondToDataRequest;
}

export interface respondToDataRequestVariables {
  files?: ScalarUpload[] | null;
  input: RespondToCandidateDataRequestInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCaseInformation
// ====================================================

export interface GetCaseInformation_screeningCase_client {
  __typename: "CandidateClient";
  name: string;
}

export interface GetCaseInformation_screeningCase_candidate {
  __typename: "Candidate";
  firstName: string;
  lastName: string;
}

export interface GetCaseInformation_screeningCase_assignee {
  __typename: "Person";
  id: string;
  name: string;
}

export interface GetCaseInformation_screeningCase_products_candidateDataRequest_attachments {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface GetCaseInformation_screeningCase_products_candidateDataRequest_replyData_attachments {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
}

export interface GetCaseInformation_screeningCase_products_candidateDataRequest_replyData {
  __typename: "CandidateDataReplyContent";
  content: string;
  attachments: GetCaseInformation_screeningCase_products_candidateDataRequest_replyData_attachments[];
}

export interface GetCaseInformation_screeningCase_products_candidateDataRequest {
  __typename: "CandidateDataRequest";
  id: string;
  attachments: GetCaseInformation_screeningCase_products_candidateDataRequest_attachments[];
  content: string;
  attachmentsAllowed: boolean;
  replyData: GetCaseInformation_screeningCase_products_candidateDataRequest_replyData | null;
}

export interface GetCaseInformation_screeningCase_products {
  __typename: "CandidateCaseProduct";
  id: string;
  status: CandidateCaseProductStatus;
  name: string;
  candidateDataRequest: GetCaseInformation_screeningCase_products_candidateDataRequest | null;
}

export interface GetCaseInformation_screeningCase {
  __typename: "CandidateCase";
  id: string;
  whenCreated: ScalarLong;
  whenCompleted: ScalarLong | null;
  status: CandidateCaseStatus;
  client: GetCaseInformation_screeningCase_client;
  candidate: GetCaseInformation_screeningCase_candidate;
  assignee: GetCaseInformation_screeningCase_assignee | null;
  products: GetCaseInformation_screeningCase_products[];
}

export interface GetCaseInformation {
  /**
   * Pending case to be filled by candidate.
   */
  screeningCase: GetCaseInformation_screeningCase;
}

export interface GetCaseInformationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: attachmentFragment
// ====================================================

export interface attachmentFragment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CandidateCaseFragment
// ====================================================

export interface CandidateCaseFragment_additionalCandidateAttachments_candidateAttachments {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface CandidateCaseFragment_additionalCandidateAttachments {
  __typename: "AdditionalCandidateAttachments";
  note: string | null;
  whenUploaded: ScalarLong;
  candidateAttachments: CandidateCaseFragment_additionalCandidateAttachments_candidateAttachments[];
}

export interface CandidateCaseFragment_candidate {
  __typename: "Candidate";
  firstName: string;
  lastName: string;
  emailAddress: string;
}

export interface CandidateCaseFragment_eSignature {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface CandidateCaseFragment_trustIdApplication_state {
  __typename: "TrustIdApplicationState";
  status: TrustIDApplicationStatus;
}

export interface CandidateCaseFragment_trustIdApplication {
  __typename: "CandidateTrustIdApplication";
  state: CandidateCaseFragment_trustIdApplication_state;
}

export interface CandidateCaseFragment_consentForm {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface CandidateCaseFragment_originalConsentForm {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface CandidateCaseFragment_client_logo {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface CandidateCaseFragment_client {
  __typename: "CandidateClient";
  id: string;
  name: string;
  liveChatEnabled: boolean;
  logo: CandidateCaseFragment_client_logo | null;
}

export interface CandidateCaseFragment_sectionsByAffectingField {
  __typename: "SectionsByAffectingField";
  conditionalProductsAffectedSections: Section[];
  fieldId: string;
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_field_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_field_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_field_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_field_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_field_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: CandidateCaseFragment_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction_values_title;
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: CandidateCaseFragment_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction_values[];
}

export type CandidateCaseFragment_sections_answers_answers_fieldValues_field_restrictions = CandidateCaseFragment_sections_answers_answers_fieldValues_field_restrictions_DateRestriction | CandidateCaseFragment_sections_answers_answers_fieldValues_field_restrictions_DurationRestriction | CandidateCaseFragment_sections_answers_answers_fieldValues_field_restrictions_GapRestriction | CandidateCaseFragment_sections_answers_answers_fieldValues_field_restrictions_LengthRestriction | CandidateCaseFragment_sections_answers_answers_fieldValues_field_restrictions_NumericRestriction | CandidateCaseFragment_sections_answers_answers_fieldValues_field_restrictions_SelectRestriction;

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_field_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_field_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_field_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_field_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_field_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type CandidateCaseFragment_sections_answers_answers_fieldValues_field_opsFields = CandidateCaseFragment_sections_answers_answers_fieldValues_field_opsFields_OpsEquifaxFieldValue | CandidateCaseFragment_sections_answers_answers_fieldValues_field_opsFields_OpsEbulkFieldValue | CandidateCaseFragment_sections_answers_answers_fieldValues_field_opsFields_OpsOcrFieldValue;

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_field {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: CandidateCaseFragment_sections_answers_answers_fieldValues_field_restrictions[];
  description: CandidateCaseFragment_sections_answers_answers_fieldValues_field_description;
  title: CandidateCaseFragment_sections_answers_answers_fieldValues_field_title;
  opsFields: CandidateCaseFragment_sections_answers_answers_fieldValues_field_opsFields[];
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_value_LongValue {
  __typename: "LongValue";
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_value_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_value_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: CandidateCaseFragment_sections_answers_answers_fieldValues_value_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_value_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_value_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_value_Attachments {
  __typename: "Attachments";
  attachmentsValue: CandidateCaseFragment_sections_answers_answers_fieldValues_value_Attachments_attachmentsValue[];
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_value_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_value_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_value_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: CandidateCaseFragment_sections_answers_answers_fieldValues_value_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_value_Company {
  __typename: "Company";
  name: string;
  address: CandidateCaseFragment_sections_answers_answers_fieldValues_value_Company_address;
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_value_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_value_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_value_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_value_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_value_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_value_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_value_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_value_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: CandidateCaseFragment_sections_answers_answers_fieldValues_value_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface CandidateCaseFragment_sections_answers_answers_fieldValues_value_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: CandidateCaseFragment_sections_answers_answers_fieldValues_value_QualificationsAndGrades_qualificationsAndGrades[];
}

export type CandidateCaseFragment_sections_answers_answers_fieldValues_value = CandidateCaseFragment_sections_answers_answers_fieldValues_value_LongValue | CandidateCaseFragment_sections_answers_answers_fieldValues_value_Address | CandidateCaseFragment_sections_answers_answers_fieldValues_value_Attachment | CandidateCaseFragment_sections_answers_answers_fieldValues_value_Attachments | CandidateCaseFragment_sections_answers_answers_fieldValues_value_BooleanValue | CandidateCaseFragment_sections_answers_answers_fieldValues_value_Company | CandidateCaseFragment_sections_answers_answers_fieldValues_value_Country | CandidateCaseFragment_sections_answers_answers_fieldValues_value_DateValue | CandidateCaseFragment_sections_answers_answers_fieldValues_value_FloatValue | CandidateCaseFragment_sections_answers_answers_fieldValues_value_StringValue | CandidateCaseFragment_sections_answers_answers_fieldValues_value_DateRange | CandidateCaseFragment_sections_answers_answers_fieldValues_value_StringValues | CandidateCaseFragment_sections_answers_answers_fieldValues_value_QualificationsAndGrades;

export interface CandidateCaseFragment_sections_answers_answers_fieldValues {
  __typename: "CandidateFieldValue";
  skip: boolean;
  field: CandidateCaseFragment_sections_answers_answers_fieldValues_field;
  value: CandidateCaseFragment_sections_answers_answers_fieldValues_value | null;
}

export interface CandidateCaseFragment_sections_answers_answers {
  __typename: "CandidateAnswerDetails";
  isCandidateAnswer: boolean;
  id: string;
  fieldValues: CandidateCaseFragment_sections_answers_answers_fieldValues[];
}

export interface CandidateCaseFragment_sections_answers_question_fields_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface CandidateCaseFragment_sections_answers_question_fields_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface CandidateCaseFragment_sections_answers_question_fields_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface CandidateCaseFragment_sections_answers_question_fields_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface CandidateCaseFragment_sections_answers_question_fields_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface CandidateCaseFragment_sections_answers_question_fields_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface CandidateCaseFragment_sections_answers_question_fields_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: CandidateCaseFragment_sections_answers_question_fields_restrictions_SelectRestriction_values_title;
}

export interface CandidateCaseFragment_sections_answers_question_fields_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: CandidateCaseFragment_sections_answers_question_fields_restrictions_SelectRestriction_values[];
}

export type CandidateCaseFragment_sections_answers_question_fields_restrictions = CandidateCaseFragment_sections_answers_question_fields_restrictions_DateRestriction | CandidateCaseFragment_sections_answers_question_fields_restrictions_DurationRestriction | CandidateCaseFragment_sections_answers_question_fields_restrictions_GapRestriction | CandidateCaseFragment_sections_answers_question_fields_restrictions_LengthRestriction | CandidateCaseFragment_sections_answers_question_fields_restrictions_NumericRestriction | CandidateCaseFragment_sections_answers_question_fields_restrictions_SelectRestriction;

export interface CandidateCaseFragment_sections_answers_question_fields_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface CandidateCaseFragment_sections_answers_question_fields_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface CandidateCaseFragment_sections_answers_question_fields_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface CandidateCaseFragment_sections_answers_question_fields_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface CandidateCaseFragment_sections_answers_question_fields_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type CandidateCaseFragment_sections_answers_question_fields_opsFields = CandidateCaseFragment_sections_answers_question_fields_opsFields_OpsEquifaxFieldValue | CandidateCaseFragment_sections_answers_question_fields_opsFields_OpsEbulkFieldValue | CandidateCaseFragment_sections_answers_question_fields_opsFields_OpsOcrFieldValue;

export interface CandidateCaseFragment_sections_answers_question_fields {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: CandidateCaseFragment_sections_answers_question_fields_restrictions[];
  description: CandidateCaseFragment_sections_answers_question_fields_description;
  title: CandidateCaseFragment_sections_answers_question_fields_title;
  opsFields: CandidateCaseFragment_sections_answers_question_fields_opsFields[];
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_fields_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_fields_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_fields_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_fields_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_fields_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: CandidateCaseFragment_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction_values_title;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: CandidateCaseFragment_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction_values[];
}

export type CandidateCaseFragment_sections_answers_question_conditionalElements_fields_restrictions = CandidateCaseFragment_sections_answers_question_conditionalElements_fields_restrictions_DateRestriction | CandidateCaseFragment_sections_answers_question_conditionalElements_fields_restrictions_DurationRestriction | CandidateCaseFragment_sections_answers_question_conditionalElements_fields_restrictions_GapRestriction | CandidateCaseFragment_sections_answers_question_conditionalElements_fields_restrictions_LengthRestriction | CandidateCaseFragment_sections_answers_question_conditionalElements_fields_restrictions_NumericRestriction | CandidateCaseFragment_sections_answers_question_conditionalElements_fields_restrictions_SelectRestriction;

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_fields_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_fields_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_fields_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_fields_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_fields_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type CandidateCaseFragment_sections_answers_question_conditionalElements_fields_opsFields = CandidateCaseFragment_sections_answers_question_conditionalElements_fields_opsFields_OpsEquifaxFieldValue | CandidateCaseFragment_sections_answers_question_conditionalElements_fields_opsFields_OpsEbulkFieldValue | CandidateCaseFragment_sections_answers_question_conditionalElements_fields_opsFields_OpsOcrFieldValue;

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_fields {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: CandidateCaseFragment_sections_answers_question_conditionalElements_fields_restrictions[];
  description: CandidateCaseFragment_sections_answers_question_conditionalElements_fields_description;
  title: CandidateCaseFragment_sections_answers_question_conditionalElements_fields_title;
  opsFields: CandidateCaseFragment_sections_answers_question_conditionalElements_fields_opsFields[];
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values_title;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values[];
}

export type CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions = CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_DateRestriction | CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_DurationRestriction | CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_GapRestriction | CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_LengthRestriction | CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_NumericRestriction | CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction;

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields = CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue | CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue | CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsOcrFieldValue;

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field_restrictions[];
  description: CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field_description;
  title: CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field_title;
  opsFields: CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field_opsFields[];
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_LongValue {
  __typename: "LongValue";
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachments {
  __typename: "Attachments";
  attachmentsValue: CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachments_attachmentsValue[];
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company {
  __typename: "Company";
  name: string;
  address: CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company_address;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades[];
}

export type CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value = CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_LongValue | CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_Address | CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachment | CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_Attachments | CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_BooleanValue | CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_Company | CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_Country | CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_DateValue | CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_FloatValue | CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_StringValue | CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_DateRange | CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_StringValues | CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades;

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue {
  __typename: "CandidateFieldValue";
  field: CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_field;
  value: CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue_value | null;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements_conditions {
  __typename: "CandidateFieldCondition";
  id: string | null;
  fieldValue: CandidateCaseFragment_sections_answers_question_conditionalElements_conditions_fieldValue;
}

export interface CandidateCaseFragment_sections_answers_question_conditionalElements {
  __typename: "CandidateQuestionConditionalFields";
  id: string | null;
  /**
   * Fields to be enabled when condition is true.
   */
  fields: CandidateCaseFragment_sections_answers_question_conditionalElements_fields[];
  conditions: CandidateCaseFragment_sections_answers_question_conditionalElements_conditions[];
}

export interface CandidateCaseFragment_sections_answers_question {
  __typename: "CandidateQuestion";
  id: string;
  /**
   *  TODO: check if sectionOrder is needed here
   * sectionOrder: CandidateSectionOrder!
   */
  conditional: boolean;
  /**
   * Whether the user can answer the question multiple times, like previous job
   */
  multipleAnswers: boolean;
  /**
   * Identity proof needs special wording/etc at the candidate portal.
   */
  questionType: QuestionType;
  title: string;
  fields: CandidateCaseFragment_sections_answers_question_fields[];
  conditionalElements: CandidateCaseFragment_sections_answers_question_conditionalElements[];
}

export interface CandidateCaseFragment_sections_answers {
  __typename: "CandidateAnswer";
  conditionFulfilled: boolean;
  answers: CandidateCaseFragment_sections_answers_answers[];
  question: CandidateCaseFragment_sections_answers_question;
}

export interface CandidateCaseFragment_sections {
  __typename: "CandidateDataSection";
  /**
   * Whether all questions in this section has been answered.
   */
  completed: boolean;
  section: Section;
  /**
   * Provides information for both questions and optional answers.
   * If some question allows multiple answers then several Answer structures come with the same questionId in this array.
   */
  answers: CandidateCaseFragment_sections_answers[];
}

export interface CandidateCaseFragment_contract_support {
  __typename: "CandidateContractSupport";
  supportEmailAddress: string;
  supportPhoneNumber: string;
}

export interface CandidateCaseFragment_contract {
  __typename: "CandidateContract";
  id: string;
  support: CandidateCaseFragment_contract_support;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction_values_title;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction_values[];
}

export type CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions = CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_DateRestriction | CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_DurationRestriction | CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_GapRestriction | CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_LengthRestriction | CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_NumericRestriction | CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions_SelectRestriction;

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields = CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue | CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue | CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields_OpsOcrFieldValue;

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field_restrictions[];
  description: CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field_description;
  title: CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field_title;
  opsFields: CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field_opsFields[];
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_LongValue {
  __typename: "LongValue";
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_Attachments {
  __typename: "Attachments";
  attachmentsValue: CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_Attachments_attachmentsValue[];
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_Company {
  __typename: "Company";
  name: string;
  address: CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_Company_address;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades[];
}

export type CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value = CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_LongValue | CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_Address | CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_Attachment | CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_Attachments | CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_BooleanValue | CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_Company | CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_Country | CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_DateValue | CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_FloatValue | CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_StringValue | CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_DateRange | CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_StringValues | CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value_QualificationsAndGrades;

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue {
  __typename: "CandidateFieldValue";
  field: CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_field;
  value: CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue_value | null;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_conditions {
  __typename: "CandidateFieldCondition";
  id: string | null;
  fieldValue: CandidateCaseFragment_products_product_conditionalQuestions_conditions_fieldValue;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_fields_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_fields_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_fields_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_fields_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_fields_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: CandidateCaseFragment_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction_values_title;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: CandidateCaseFragment_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction_values[];
}

export type CandidateCaseFragment_products_product_conditionalQuestions_question_fields_restrictions = CandidateCaseFragment_products_product_conditionalQuestions_question_fields_restrictions_DateRestriction | CandidateCaseFragment_products_product_conditionalQuestions_question_fields_restrictions_DurationRestriction | CandidateCaseFragment_products_product_conditionalQuestions_question_fields_restrictions_GapRestriction | CandidateCaseFragment_products_product_conditionalQuestions_question_fields_restrictions_LengthRestriction | CandidateCaseFragment_products_product_conditionalQuestions_question_fields_restrictions_NumericRestriction | CandidateCaseFragment_products_product_conditionalQuestions_question_fields_restrictions_SelectRestriction;

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_fields_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_fields_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_fields_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_fields_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_fields_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type CandidateCaseFragment_products_product_conditionalQuestions_question_fields_opsFields = CandidateCaseFragment_products_product_conditionalQuestions_question_fields_opsFields_OpsEquifaxFieldValue | CandidateCaseFragment_products_product_conditionalQuestions_question_fields_opsFields_OpsEbulkFieldValue | CandidateCaseFragment_products_product_conditionalQuestions_question_fields_opsFields_OpsOcrFieldValue;

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_fields {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: CandidateCaseFragment_products_product_conditionalQuestions_question_fields_restrictions[];
  description: CandidateCaseFragment_products_product_conditionalQuestions_question_fields_description;
  title: CandidateCaseFragment_products_product_conditionalQuestions_question_fields_title;
  opsFields: CandidateCaseFragment_products_product_conditionalQuestions_question_fields_opsFields[];
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction_values_title;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction_values[];
}

export type CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions = CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_DateRestriction | CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_DurationRestriction | CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_GapRestriction | CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_LengthRestriction | CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_NumericRestriction | CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions_SelectRestriction;

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields = CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsEquifaxFieldValue | CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsEbulkFieldValue | CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields_OpsOcrFieldValue;

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields_restrictions[];
  description: CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields_description;
  title: CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields_title;
  opsFields: CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields_opsFields[];
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values_title;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction_values[];
}

export type CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions = CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_DateRestriction | CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_DurationRestriction | CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_GapRestriction | CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_LengthRestriction | CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_NumericRestriction | CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions_SelectRestriction;

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields = CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue | CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue | CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields_OpsOcrFieldValue;

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_restrictions[];
  description: CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_description;
  title: CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_title;
  opsFields: CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field_opsFields[];
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_LongValue {
  __typename: "LongValue";
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachments {
  __typename: "Attachments";
  attachmentsValue: CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachments_attachmentsValue[];
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company {
  __typename: "Company";
  name: string;
  address: CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company_address;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades[];
}

export type CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value = CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_LongValue | CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Address | CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachment | CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Attachments | CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_BooleanValue | CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Company | CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_Country | CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_DateValue | CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_FloatValue | CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_StringValue | CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_DateRange | CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_StringValues | CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value_QualificationsAndGrades;

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue {
  __typename: "CandidateFieldValue";
  field: CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_field;
  value: CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue_value | null;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions {
  __typename: "CandidateFieldCondition";
  id: string | null;
  fieldValue: CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions_fieldValue;
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements {
  __typename: "CandidateQuestionConditionalFields";
  id: string | null;
  /**
   * Fields to be enabled when condition is true.
   */
  fields: CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_fields[];
  conditions: CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements_conditions[];
}

export interface CandidateCaseFragment_products_product_conditionalQuestions_question {
  __typename: "CandidateQuestion";
  id: string;
  /**
   *  TODO: check if sectionOrder is needed here
   * sectionOrder: CandidateSectionOrder!
   */
  conditional: boolean;
  /**
   * Whether the user can answer the question multiple times, like previous job
   */
  multipleAnswers: boolean;
  /**
   * Identity proof needs special wording/etc at the candidate portal.
   */
  questionType: QuestionType;
  title: string;
  fields: CandidateCaseFragment_products_product_conditionalQuestions_question_fields[];
  conditionalElements: CandidateCaseFragment_products_product_conditionalQuestions_question_conditionalElements[];
}

export interface CandidateCaseFragment_products_product_conditionalQuestions {
  __typename: "CandidateProductConditionalQuestion";
  id: string | null;
  conditions: CandidateCaseFragment_products_product_conditionalQuestions_conditions[];
  /**
   * Questions to be enabled when condition is true.
   */
  question: CandidateCaseFragment_products_product_conditionalQuestions_question;
}

export interface CandidateCaseFragment_products_product_conditionalValidations_answersCondition {
  __typename: "CandidateAnswersCondition";
  maxNumberOfAnswers: number | null;
  minNumberOfAnswers: number | null;
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction_values_title;
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction_values[];
}

export type CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field_restrictions = CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_DateRestriction | CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_DurationRestriction | CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_GapRestriction | CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_LengthRestriction | CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_NumericRestriction | CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field_restrictions_SelectRestriction;

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field_opsFields = CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsEquifaxFieldValue | CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsEbulkFieldValue | CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field_opsFields_OpsOcrFieldValue;

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field_restrictions[];
  description: CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field_description;
  title: CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field_title;
  opsFields: CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field_opsFields[];
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_LongValue {
  __typename: "LongValue";
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_Attachments {
  __typename: "Attachments";
  attachmentsValue: CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_Attachments_attachmentsValue[];
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_Company {
  __typename: "Company";
  name: string;
  address: CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_Company_address;
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades_qualificationsAndGrades[];
}

export type CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value = CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_LongValue | CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_Address | CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_Attachment | CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_Attachments | CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_BooleanValue | CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_Company | CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_Country | CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_DateValue | CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_FloatValue | CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_StringValue | CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_DateRange | CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_StringValues | CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value_QualificationsAndGrades;

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue {
  __typename: "CandidateFieldValue";
  field: CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_field;
  value: CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue_value | null;
}

export interface CandidateCaseFragment_products_product_conditionalValidations_conditions {
  __typename: "CandidateFieldCondition";
  id: string | null;
  fieldValue: CandidateCaseFragment_products_product_conditionalValidations_conditions_fieldValue;
}

export interface CandidateCaseFragment_products_product_conditionalValidations {
  __typename: "CandidateProductConditionalValidation";
  id: string | null;
  validationMessage: string;
  answersCondition: CandidateCaseFragment_products_product_conditionalValidations_answersCondition | null;
  conditions: CandidateCaseFragment_products_product_conditionalValidations_conditions[];
}

export interface CandidateCaseFragment_products_product {
  __typename: "CandidateProduct";
  id: string;
  conditionalQuestions: CandidateCaseFragment_products_product_conditionalQuestions[];
  conditionalValidations: CandidateCaseFragment_products_product_conditionalValidations[];
}

export interface CandidateCaseFragment_products {
  __typename: "CandidateCaseProduct";
  id: string;
  status: CandidateCaseProductStatus;
  name: string;
  product: CandidateCaseFragment_products_product;
}

export interface CandidateCaseFragment {
  __typename: "CandidateCase";
  id: string;
  code: string;
  status: CandidateCaseStatus;
  /**
   * Assumption: deadline is hard-coded number of days for now
   */
  deadline: ScalarLong;
  ocrTriggered: boolean;
  additionalCandidateAttachments: CandidateCaseFragment_additionalCandidateAttachments[];
  candidate: CandidateCaseFragment_candidate;
  eSignature: CandidateCaseFragment_eSignature | null;
  consentType: ConsentType;
  consentText: string | null;
  troubleshotByScreener: boolean;
  consentGiven: boolean;
  whenCreated: ScalarLong;
  whenCompleted: ScalarLong | null;
  trustIdApplication: CandidateCaseFragment_trustIdApplication | null;
  draft: string | null;
  hasEnhancedCriminalityCheck: boolean;
  consentForm: CandidateCaseFragment_consentForm | null;
  originalConsentForm: CandidateCaseFragment_originalConsentForm | null;
  client: CandidateCaseFragment_client;
  sectionsByAffectingField: CandidateCaseFragment_sectionsByAffectingField[];
  sections: CandidateCaseFragment_sections[];
  contract: CandidateCaseFragment_contract;
  products: CandidateCaseFragment_products[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: troubleshootingRequestFragment
// ====================================================

export interface troubleshootingRequestFragment {
  __typename: "CandidateCaseTroubleshootingRequest";
  id: string;
  status: TroubleshootingRequestStatus;
  createdWhen: ScalarLong;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: candidateFieldFragment
// ====================================================

export interface candidateFieldFragment_restrictions_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface candidateFieldFragment_restrictions_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface candidateFieldFragment_restrictions_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface candidateFieldFragment_restrictions_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface candidateFieldFragment_restrictions_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface candidateFieldFragment_restrictions_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface candidateFieldFragment_restrictions_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: candidateFieldFragment_restrictions_SelectRestriction_values_title;
}

export interface candidateFieldFragment_restrictions_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: candidateFieldFragment_restrictions_SelectRestriction_values[];
}

export type candidateFieldFragment_restrictions = candidateFieldFragment_restrictions_DateRestriction | candidateFieldFragment_restrictions_DurationRestriction | candidateFieldFragment_restrictions_GapRestriction | candidateFieldFragment_restrictions_LengthRestriction | candidateFieldFragment_restrictions_NumericRestriction | candidateFieldFragment_restrictions_SelectRestriction;

export interface candidateFieldFragment_description {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface candidateFieldFragment_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface candidateFieldFragment_opsFields_OpsEquifaxFieldValue {
  __typename: "OpsEquifaxFieldValue" | "OpsKonfirFieldValue" | "OpsTrustIdFieldValue";
}

export interface candidateFieldFragment_opsFields_OpsEbulkFieldValue {
  __typename: "OpsEbulkFieldValue";
  ebulkValue: OpsEbulkFieldType;
}

export interface candidateFieldFragment_opsFields_OpsOcrFieldValue {
  __typename: "OpsOcrFieldValue";
  ocrValue: OpsOcrFieldType;
}

export type candidateFieldFragment_opsFields = candidateFieldFragment_opsFields_OpsEquifaxFieldValue | candidateFieldFragment_opsFields_OpsEbulkFieldValue | candidateFieldFragment_opsFields_OpsOcrFieldValue;

export interface candidateFieldFragment {
  __typename: "CandidateField";
  /**
   * Field ID within question.
   */
  id: string;
  type: FieldType;
  fieldType: HandleableFieldType;
  /**
   * Number of fractional digits 0-5, if the field type is Number
   */
  required: boolean;
  conditional: boolean;
  /**
   * Field value restrictions
   */
  restrictions: candidateFieldFragment_restrictions[];
  description: candidateFieldFragment_description;
  title: candidateFieldFragment_title;
  opsFields: candidateFieldFragment_opsFields[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: countryFragment
// ====================================================

export interface countryFragment {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: fieldAnyValueFragment
// ====================================================

export interface fieldAnyValueFragment_LongValue {
  __typename: "LongValue";
}

export interface fieldAnyValueFragment_Address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface fieldAnyValueFragment_Address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: fieldAnyValueFragment_Address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface fieldAnyValueFragment_Attachment {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface fieldAnyValueFragment_Attachments_attachmentsValue {
  __typename: "Attachment";
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface fieldAnyValueFragment_Attachments {
  __typename: "Attachments";
  attachmentsValue: fieldAnyValueFragment_Attachments_attachmentsValue[];
}

export interface fieldAnyValueFragment_BooleanValue {
  __typename: "BooleanValue";
  booleanValue: boolean;
}

export interface fieldAnyValueFragment_Company_address_country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface fieldAnyValueFragment_Company_address {
  __typename: "Address";
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  country: fieldAnyValueFragment_Company_address_country;
  county: string | null;
  postcode: string;
  town: string;
}

export interface fieldAnyValueFragment_Company {
  __typename: "Company";
  name: string;
  address: fieldAnyValueFragment_Company_address;
}

export interface fieldAnyValueFragment_Country {
  __typename: "Country";
  id: string;
  name: string;
  isoCode: string;
}

export interface fieldAnyValueFragment_DateValue {
  __typename: "DateValue";
  dateValue: ScalarDate;
}

export interface fieldAnyValueFragment_FloatValue {
  __typename: "FloatValue";
  floatValue: number;
}

export interface fieldAnyValueFragment_StringValue {
  __typename: "StringValue";
  stringValue: string;
}

export interface fieldAnyValueFragment_DateRange {
  __typename: "DateRange";
  /**
   * From date (inclusive).
   */
  from: ScalarDate | null;
  /**
   * To date (inclusive).
   */
  to: ScalarDate | null;
}

export interface fieldAnyValueFragment_StringValues {
  __typename: "StringValues";
  stringValues: string[];
}

export interface fieldAnyValueFragment_QualificationsAndGrades_qualificationsAndGrades_grades {
  __typename: "GradeTable";
  grade: string | null;
  subject: string | null;
}

export interface fieldAnyValueFragment_QualificationsAndGrades_qualificationsAndGrades {
  __typename: "QualificationAndGrades";
  qualification: string;
  grades: fieldAnyValueFragment_QualificationsAndGrades_qualificationsAndGrades_grades[];
}

export interface fieldAnyValueFragment_QualificationsAndGrades {
  __typename: "QualificationsAndGrades";
  qualificationsAndGrades: fieldAnyValueFragment_QualificationsAndGrades_qualificationsAndGrades[];
}

export type fieldAnyValueFragment = fieldAnyValueFragment_LongValue | fieldAnyValueFragment_Address | fieldAnyValueFragment_Attachment | fieldAnyValueFragment_Attachments | fieldAnyValueFragment_BooleanValue | fieldAnyValueFragment_Company | fieldAnyValueFragment_Country | fieldAnyValueFragment_DateValue | fieldAnyValueFragment_FloatValue | fieldAnyValueFragment_StringValue | fieldAnyValueFragment_DateRange | fieldAnyValueFragment_StringValues | fieldAnyValueFragment_QualificationsAndGrades;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: fieldRestrictionFragment
// ====================================================

export interface fieldRestrictionFragment_DateRestriction {
  __typename: "DateRestriction";
  comp: CompOperator;
  dateValue: ScalarDate;
}

export interface fieldRestrictionFragment_DurationRestriction {
  __typename: "DurationRestriction";
  durationValue: number;
  unit: DateUnit;
}

export interface fieldRestrictionFragment_GapRestriction {
  __typename: "GapRestriction";
  gapValue: number;
  unit: DateUnit;
}

export interface fieldRestrictionFragment_LengthRestriction {
  __typename: "LengthRestriction";
  maxLength: number | null;
  minLength: number | null;
}

export interface fieldRestrictionFragment_NumericRestriction {
  __typename: "NumericRestriction";
  comp: CompOperator;
  numericValue: number;
}

export interface fieldRestrictionFragment_SelectRestriction_values_title {
  __typename: "LocalizedString";
  /**
   * Translation in current locale.
   */
  current: string;
}

export interface fieldRestrictionFragment_SelectRestriction_values {
  __typename: "SelectValue";
  id: string;
  title: fieldRestrictionFragment_SelectRestriction_values_title;
}

export interface fieldRestrictionFragment_SelectRestriction {
  __typename: "SelectRestriction";
  multiSelect: boolean;
  values: fieldRestrictionFragment_SelectRestriction_values[];
}

export type fieldRestrictionFragment = fieldRestrictionFragment_DateRestriction | fieldRestrictionFragment_DurationRestriction | fieldRestrictionFragment_GapRestriction | fieldRestrictionFragment_LengthRestriction | fieldRestrictionFragment_NumericRestriction | fieldRestrictionFragment_SelectRestriction;

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CandidateCaseProductStatus {
  AWAITING_3RD_PARTY = "AWAITING_3RD_PARTY",
  AWAITING_CANDIDATE = "AWAITING_CANDIDATE",
  AWAITING_REFERENCE = "AWAITING_REFERENCE",
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  READY_TO_START = "READY_TO_START",
}

export enum CandidateCaseStatus {
  ACTIVE = "ACTIVE",
  CANDIDATE_ACTION_REQUIRED = "CANDIDATE_ACTION_REQUIRED",
  INACTIVE = "INACTIVE",
  IN_PROGRESS = "IN_PROGRESS",
  SUBMITTED = "SUBMITTED",
}

export enum CandidatePortalAuthFlow {
  AZURE_AD_SIGN_IN = "AZURE_AD_SIGN_IN",
  AZURE_AD_SIGN_IN_OR_SIGN_UP = "AZURE_AD_SIGN_IN_OR_SIGN_UP",
  AZURE_AD_SIGN_UP = "AZURE_AD_SIGN_UP",
}

export enum CaseTroubleshoootingHistoryType {
  FIELD_VALUE_UPDATED = "FIELD_VALUE_UPDATED",
  FILE_UPLOAD = "FILE_UPLOAD",
}

export enum CompOperator {
  EQ = "EQ",
  GT = "GT",
  GTE = "GTE",
  LT = "LT",
  LTE = "LTE",
}

export enum ConsentType {
  DIGITAL = "DIGITAL",
  E_SIGNATURE = "E_SIGNATURE",
  WET_SIGNATURE = "WET_SIGNATURE",
}

export enum DateUnit {
  DAY = "DAY",
  MONTH = "MONTH",
  YEAR = "YEAR",
}

export enum FieldType {
  ADDRESS = "ADDRESS",
  BOOLEAN = "BOOLEAN",
  COMPANY = "COMPANY",
  CONTACT_EMPLOYER = "CONTACT_EMPLOYER",
  COST_CENTER = "COST_CENTER",
  COUNTRY = "COUNTRY",
  DATE = "DATE",
  DATE_RANGE = "DATE_RANGE",
  EMAIL = "EMAIL",
  FIRST_NAME = "FIRST_NAME",
  HIRING_MANAGER_EMAIL = "HIRING_MANAGER_EMAIL",
  INTEGER = "INTEGER",
  LABEL = "LABEL",
  MULTIPLE_STRING = "MULTIPLE_STRING",
  MULTIPLE_UPLOAD = "MULTIPLE_UPLOAD",
  NATIONAL_INSURANCE_NUMBER = "NATIONAL_INSURANCE_NUMBER",
  PASSPORT = "PASSPORT",
  PHONE_NUMBER = "PHONE_NUMBER",
  QUALIFICATIONS_AND_GRADES = "QUALIFICATIONS_AND_GRADES",
  SELECT = "SELECT",
  STRING = "STRING",
  SURNAME = "SURNAME",
  UPLOAD = "UPLOAD",
}

/**
 * We need special handling for some of fields. So we need to identify them.
 */
export enum HandleableFieldType {
  ACTIVITY_TYPE = "ACTIVITY_TYPE",
  CURRENT_ADDRESS = "CURRENT_ADDRESS",
  CURRENT_EMPLOYER = "CURRENT_EMPLOYER",
  CURRENT_POSITION = "CURRENT_POSITION",
  OTHER = "OTHER",
}

export enum OpsEbulkFieldType {
  ADDRESS_HISTORY = "ADDRESS_HISTORY",
  APPLICANT_TITLE = "APPLICANT_TITLE",
  BIRTH_COUNTRY = "BIRTH_COUNTRY",
  BIRTH_DATE = "BIRTH_DATE",
  BIRTH_NATIONALITY = "BIRTH_NATIONALITY",
  BIRTH_TOWN = "BIRTH_TOWN",
  CURRENT_NATIONALITY = "CURRENT_NATIONALITY",
  DRIVING_LICENCE_ISSUER_COUNTRY = "DRIVING_LICENCE_ISSUER_COUNTRY",
  DRIVING_LICENCE_ISSUE_DATE = "DRIVING_LICENCE_ISSUE_DATE",
  DRIVING_LICENCE_NUMBER = "DRIVING_LICENCE_NUMBER",
  EMAIL_ADDRESS = "EMAIL_ADDRESS",
  EMPLOYMENT_SECTOR_TYPE = "EMPLOYMENT_SECTOR_TYPE",
  FORENAME = "FORENAME",
  GENDER = "GENDER",
  IDENTITY_CHECKED_BY = "IDENTITY_CHECKED_BY",
  MIDDLE_NAME = "MIDDLE_NAME",
  MOTHER_MAIDEN_NAME = "MOTHER_MAIDEN_NAME",
  NATIONALITY_IS_CHANGED = "NATIONALITY_IS_CHANGED",
  NATIONAL_INSURANCE_NUMBER = "NATIONAL_INSURANCE_NUMBER",
  OTHER_FORENAMES = "OTHER_FORENAMES",
  OTHER_MIDDLE_NAMES = "OTHER_MIDDLE_NAMES",
  OTHER_SURNAMES = "OTHER_SURNAMES",
  PASSPORT_ISSUED_DATE = "PASSPORT_ISSUED_DATE",
  PASSPORT_ISSUER_COUNTRY = "PASSPORT_ISSUER_COUNTRY",
  PASSPORT_NUMBER = "PASSPORT_NUMBER",
  RECEIVE_PAPER_CERTIFICATE = "RECEIVE_PAPER_CERTIFICATE",
  RECEIVE_PAPER_CERTIFICATE_AT_CURRENT_ADDRESS = "RECEIVE_PAPER_CERTIFICATE_AT_CURRENT_ADDRESS",
  RECEIVE_PAPER_CERTIFICATE_RECIPIENT_ADDRESS = "RECEIVE_PAPER_CERTIFICATE_RECIPIENT_ADDRESS",
  RECEIVE_PAPER_CERTIFICATE_RECIPIENT_DEPARTMENT = "RECEIVE_PAPER_CERTIFICATE_RECIPIENT_DEPARTMENT",
  RECEIVE_PAPER_CERTIFICATE_RECIPIENT_NAME = "RECEIVE_PAPER_CERTIFICATE_RECIPIENT_NAME",
  ROLE_LOCATION_IS_SCOTLAND = "ROLE_LOCATION_IS_SCOTLAND",
  SURNAME = "SURNAME",
  UNSPENT_CONVICTIONS = "UNSPENT_CONVICTIONS",
}

export enum OpsOcrFieldType {
  ADDRESS = "ADDRESS",
  AUTHORITY = "AUTHORITY",
  CONDITIONAL_COUNTRY_ID = "CONDITIONAL_COUNTRY_ID",
  CONDITIONAL_DOCUMENT_TYPE = "CONDITIONAL_DOCUMENT_TYPE",
  COUNTRY_CODE = "COUNTRY_CODE",
  DATE_OF_BIRTH = "DATE_OF_BIRTH",
  EXPIRY_DATE = "EXPIRY_DATE",
  FIRST_NAME = "FIRST_NAME",
  GENDER = "GENDER",
  ISSUE_DATE = "ISSUE_DATE",
  LAST_NAME = "LAST_NAME",
  MIDDLE_NAME = "MIDDLE_NAME",
  PASSPORT_NUMBER = "PASSPORT_NUMBER",
  PLACE_OF_BIRTH = "PLACE_OF_BIRTH",
}

/**
 * question queries
 */
export enum QuestionType {
  ADDRESS_HISTORY = "ADDRESS_HISTORY",
  EMPLOYMENT_EDUCATION_HISTORY = "EMPLOYMENT_EDUCATION_HISTORY",
  IDENTITY_PROOF = "IDENTITY_PROOF",
  OTHER = "OTHER",
}

export enum Section {
  ADDRESS_HISTORY = "ADDRESS_HISTORY",
  CLIENT_INFORMATION = "CLIENT_INFORMATION",
  DECLARATION = "DECLARATION",
  EMPLOYMENT_EDUCATION = "EMPLOYMENT_EDUCATION",
  KONFIR = "KONFIR",
  PERSONAL_INFORMATION = "PERSONAL_INFORMATION",
  PERSONAL_REFEREES = "PERSONAL_REFEREES",
  PROFESSIONAL_MEMBERSHIP_AND_QUALIFICATION = "PROFESSIONAL_MEMBERSHIP_AND_QUALIFICATION",
  TERMS_AND_CONDITIONS = "TERMS_AND_CONDITIONS",
  TRUST_ID = "TRUST_ID",
}

export enum TroubleshootingRequestStatus {
  CANDIDATE_ACCEPTED = "CANDIDATE_ACCEPTED",
  CANDIDATE_REJECTED = "CANDIDATE_REJECTED",
  CLOSED_BY_SCREENER = "CLOSED_BY_SCREENER",
  CLOSED_BY_TIMEOUT = "CLOSED_BY_TIMEOUT",
  SCREENER_REQUESTED = "SCREENER_REQUESTED",
  TROUBLESHOOTING_FINISHED = "TROUBLESHOOTING_FINISHED",
}

export enum TrustIDApplicationStatus {
  CONTAINER_SUBMITTED = "CONTAINER_SUBMITTED",
  GUEST_LINK_CREATED = "GUEST_LINK_CREATED",
  RESULT_RECEIVED = "RESULT_RECEIVED",
  TIMED_OUT = "TIMED_OUT",
}

export interface AddressInput {
  addressLine1: string;
  addressLine2?: string | null;
  addressLine3?: string | null;
  countryId: string;
  county?: string | null;
  postcode: string;
  town: string;
}

export interface AnswerDetailsInput {
  fields: FieldValueInput[];
  id?: string | null;
  removeAnswerDetails?: RemoveAnswerDetailsInput | null;
}

export interface AnswerInput {
  answers?: AnswerDetailsInput[] | null;
  questionId: string;
}

export interface AttachmentInput {
  activationRequired?: boolean | null;
  downloadUrl: string;
  fileName: string;
  id: string;
  uploadDate: ScalarLong;
  uploadedBy: string;
}

export interface CandidateCaseSaveDraftInput {
  caseId: string;
  draft: string;
  draftTroubleshootingHistory?: (CaseTroubleshootingHistoryInput | null)[] | null;
}

export interface CandidateCaseSubmitInput {
  caseId: string;
}

export interface CandidateCaseTroubleshootingRequestUpdateInput {
  caseId: string;
  id: string;
  status: TroubleshootingRequestStatus;
}

export interface CandidateCaseUpdateInput {
  answers: AnswerInput[];
  caseId: string;
  consentFormUploadIndex?: number | null;
  consentGiven: boolean;
  eSignatureUploadIndex?: number | null;
  parseAnswers?: boolean | null;
}

export interface CandidateKonfirVerificationLinkCreateInput {
  caseId: string;
}

export interface CandidateTrustIdGuestLinkCreateInput {
  caseId: string;
}

export interface CandidateUploadAdditionalDocumentInput {
  caseId: string;
  note?: string | null;
}

export interface CaseTroubleshootingHistoryInput {
  caseId: string;
  fieldId: string;
  from?: FieldValueInput | null;
  index: ScalarLong;
  to?: FieldValueInput | null;
  type: CaseTroubleshoootingHistoryType;
}

export interface CompanyInput {
  address?: AddressInput | null;
  name?: string | null;
}

export interface CountryInput {
  id: string;
  isoCode?: string | null;
}

/**
 * Possibly unbound date range. Either "from" or "to" have to be set.
 */
export interface DateRangeInput {
  from?: ScalarDate | null;
  to?: ScalarDate | null;
}

export interface FieldValueInput {
  addressValue?: AddressInput | null;
  attachmentValue?: AttachmentInput | null;
  attachmentValues?: AttachmentInput[] | null;
  booleanValue?: boolean | null;
  companyValue?: CompanyInput | null;
  countryValue?: CountryInput | null;
  dateRangeValue?: DateRangeInput | null;
  dateValue?: ScalarDate | null;
  fieldId: string;
  floatValue?: number | null;
  idValue?: string | null;
  longValue?: ScalarLong | null;
  qualificationsAndGradesValue?: QualificationsAndGradesInput | null;
  stringValue?: string | null;
  stringValues?: string[] | null;
  uploadIndex?: number | null;
  uploadIndexes?: number[] | null;
}

export interface GradeTableInput {
  grade?: string | null;
  subject?: string | null;
}

export interface QualificationAndGradesInput {
  grades: GradeTableInput[];
  qualification: string;
}

export interface QualificationsAndGradesInput {
  qualificationsAndGrades: QualificationAndGradesInput[];
}

export interface RemoveAnswerDetailsInput {
  removeFromBilling: boolean;
}

/**
 * DataRequest
 */
export interface RespondToCandidateDataRequestInput {
  content: string;
  id: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

import React, { FC, memo, useEffect } from "react";
import { usePreviewImage } from "src/hooks/usePreviewImage";
import { LogoColorFulIcon } from "@security-watchdog/sw-ui-kit";
import { attachmentFragment } from "src/graphQLTypes";
import * as s from "./styles";
import { useReactiveVar } from "@apollo/client";
import { headerCustomLogoState } from "src/cache";

export type LogoProps = {
  logo: attachmentFragment | null;
  isDataReady?: boolean;
};

export const Component: FC<LogoProps> = ({ logo, isDataReady }) => {
  const { imageUrl } = usePreviewImage(logo);

  const logoImageUrl = useReactiveVar(headerCustomLogoState);

  useEffect(() => {
    if (!logoImageUrl && imageUrl) {
      headerCustomLogoState(imageUrl);
    }
  }, [imageUrl, logoImageUrl]);

  if (isDataReady && logoImageUrl) {
    return (
      <s.DynamicLogo
        src={logoImageUrl}
        alt="Dynamic Logo"
        data-testid="dynamic-logo"
      />
    );
  }

  if (isDataReady && !logoImageUrl && !logo) {
    return (
      <s.StaticLogoContainer data-testid="static-logo">
        <LogoColorFulIcon />
      </s.StaticLogoContainer>
    );
  }

  return null;
};

Component.displayName = "Logo";

export const Logo = memo(Component);

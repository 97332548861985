import styled from "styled-components";

export const DynamicLogo = styled.img`
  height: 100%;
`;

export const StaticLogoContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

import { attachmentFragment } from "src/graphQLTypes";
import { useCallback, useEffect, useState } from "react";
import { downloadFile } from "src/utils";

export const usePreviewImage = (file?: attachmentFragment | null) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const setupImage = useCallback(async () => {
    if (!file) {
      setImageUrl(null);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    const blob = await downloadFile(file, true);

    const objectUrl = blob ? URL.createObjectURL(blob) : null;

    setImageUrl(objectUrl);
    setIsLoading(false);
  }, [file]);

  useEffect(() => {
    setupImage();
  }, [setupImage]);

  return { imageUrl, isLoading };
};

import axios, { AxiosResponse } from "axios";
import { saveAs } from "file-saver";
import { attachmentFragment } from "src/graphQLTypes";
import { getSessionAuthToken } from "src/msal";

export const downloadFile = async (
  attachment: attachmentFragment,
  preventDownload: boolean = false
): Promise<Blob | undefined> => {
  try {
    const response: AxiosResponse<Blob> = await axios.get(
      attachment.downloadUrl,
      {
        headers: {
          authorization: await getSessionAuthToken()
        },
        responseType: "blob",
        baseURL: `${window.location.origin}/`
      }
    );

    const blob = new Blob([response.data]);

    if (!preventDownload) {
      saveAs(blob, attachment.fileName);
    }

    return blob;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};
